import React from "react";
import "./Loading.scss";
import Logo from "../../assets/ChurchLogo.png";
import { RotatingLines } from "react-loader-spinner";

const Loading = () => {
  return (
    <div id="loading-page">
      <div>
        <img src={Logo} alt="Royal Evangelical Church" />
      </div>
      <RotatingLines
        visible={true}
        height="46"
        width="46"
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default Loading;
