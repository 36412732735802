import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

const BarChart = ({chartData}) => {
    
  return (
    <Bar data={chartData}/>
    
  )
}

export default BarChart