import React,{useState} from 'react'
import './ProfitLoss.scss'
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";
import axios from 'axios';

const ProfitLossStatement = () => {
    const [incomeStatement, setIncomeStatement] = useState({})
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [gettingData, setGettingData] = useState(false)

    const getIncomeStatement = async (e) => {
        e.preventDefault();
        if(localStorage.getItem("access")){
            setGettingData(true)
            const config = {
                headers:{
                    "Content-Type": "application/json",
                    Authorization:"Bearer " + localStorage.getItem("access"),
                }
            }
            
            
            try {
                await axios.get(`${api_url}/finance/income-statement/${startDate}/${endDate}/`, config).then((response) => {
                    setIncomeStatement(response.data)
                    setGettingData(false)
                })
                
            } catch (error) {
                setGettingData(false)
                console.log(error)
                toast.error("An error occurred.")    
            }
        }
    }

  return (
    <div id='profit-loss-page'>
        <div className="filtering-grounds">
            <form onSubmit={(e)=>getIncomeStatement(e)}>
                <div className="form-control">
                    <label htmlFor="">Start Date</label>
                    <input required onChange={e=>setStartDate(e.target.value)} type="date" name="start_date" id="" />
                </div>
                <div className="form-control">
                    <label htmlFor="">End Date</label>
                    <input required onChange={e=>setEndDate(e.target.value)} type="date" name="end_date" id="" />
                </div>
                {
                    gettingData ? (
                        <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#030166"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                    ):(

                        <input type="submit" value="Get Data" />
                    )
                }
            </form>
        </div>
        <div className="profit-loss-statement">
            <table className="profit-loss-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Amount (GHS)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <b>Income</b>
                        </td>
                        <td></td>
                    </tr>
                    {
                        incomeStatement?.incomes?.map((income,index)=>(
                            <tr key={index}>
                                <td>
                                    {income.income_type}
                                </td>
                                <td style={{textAlign:"right"}}>
                                    {income?.amount}
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td>
                            <b>Expense</b>
                        </td>
                        <td></td>
                    </tr>
                    {
                        incomeStatement?.expenses?.map((expense,index)=>(
                            <tr key={index}>
                                <td>
                                    {expense.expense_type}
                                </td>
                                <td style={{textAlign:"right"}}>
                                    {expense?.amount}
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td>
                            <b>NET INCOME</b>
                        </td>
                        <td style={{textAlign:"right"}}>
                            {incomeStatement?.net_income}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default ProfitLossStatement