import React from "react";
import "./Leadership.scss";
import Footer from "../../WebsiteComponents/Footer/Footer";
import Navbar from "../../WebsiteComponents/Navbar/Navbar";

// ASSETS
import TopImage from "../../assets/CarouselImages/carouselOne.png";
import PastorWife from "../../assets/leadersImages/pastorAndWife.png";
import PastorImg from "../../assets/leadersImages/pastorImg.png";
import WifeImg from "../../assets/leadersImages/wifeImg.png";
import FounderImg from "../../assets/leadersImages/founderImg.png";
import PresidingBishop from "../../assets/leadersImages/presidingBishop.png";

const Leadership = () => {
  return (
    <div>
      <Navbar />
      <div id="leadership-page">
        <div className="top-image">
          <img src={TopImage} alt="" />
        </div>
        <div className="meet-our-founders">
          <h3 className="title">Meet Our Founders</h3>
          <div className="founders">
            <div className="founders-one">
              <img src={PastorImg} alt="pastor" className="pastor-img" />
              <img src={WifeImg} alt="wife" className="wife-img" />
            </div>
            <div className="founders-two">
              <img src={PastorWife} alt="pastor and wife" />
            </div>
          </div>
          <p className="founders-names">(Rev) Mr. & Mrs. Appiah</p>
        </div>
        <div className="about-founder-section">
          <div className="description">
            <div className="description-top">
              <h3 className="founder-name">REV. PATRICK APPIAH</h3>
              <div className="underline"></div>
            </div>
            <div className="description-details">
              <p className="introduction">Introduction</p>
              <p className="about-founder">
                Patrick Appiah (born on 22 March 1962) is a Ghanaian Christian
                minister, international speaker and author of several Christian
                books. He is the Founder and Presiding Bishop of Royal
                Evangelical Church – a Pentecostal-Charismatic ministry. Dr
                Emmanuel Appiagyei remarked on him by stating “It is very rare
                in the calling of God to see the apostolic, evangelistic,
                prophetical, pastoral and teaching call all manifested through
                one ministry. But there, it is – in Rev. Patrick Appiah
              </p>
            </div>
          </div>
          <div className="founder-img">
            <img src={FounderImg} alt="" />
          </div>
        </div>

        <div className="about-founder-section">
          <div className="description">
            <div className="description-top">
              <h3 className="founder-name">REV. PATRICK APPIAH</h3>
              <div className="underline"></div>
            </div>
            <div className="description-details">
              <p className="introduction">Introduction</p>
              <p className="about-founder">
                Patrick Appiah (born on 22 March 1962) is a Ghanaian Christian
                minister, international speaker and author of several Christian
                books. He is the Founder and Presiding Bishop of Royal
                Evangelical Church – a Pentecostal-Charismatic ministry. Dr
                Emmanuel Appiagyei remarked on him by stating “It is very rare
                in the calling of God to see the apostolic, evangelistic,
                prophetical, pastoral and teaching call all manifested through
                one ministry. But there, it is – in Rev. Patrick Appiah
              </p>
            </div>
          </div>
          <div className="founder-img">
            <img src={FounderImg} alt="" />
          </div>
        </div>

        <div className="executive-council">
            <div className="header">
                <h3 className="title">
                    Executive Council
                </h3>
                <div className="underline"></div>
            </div>
            <div className="council-members">
                <div className="council-member">
                    <div className="member-img">
                        <img src={PresidingBishop} alt="" />
                    </div>
                    <div className="member-name-position">
                        <p className="member-position">
                            Presiding Bishop
                        </p>
                        <p className="member-name">
                            Edwin Amoakohene Amponsah
                        </p>
                    </div>
                </div>

                <div className="council-member">
                    <div className="member-img">
                        <img src={PresidingBishop} alt="" />
                    </div>
                    <div className="member-name-position">
                        <p className="member-position">
                            Presiding Bishop
                        </p>
                        <p className="member-name">
                            Edwin Amoakohene Amponsah
                        </p>
                    </div>
                </div>

                <div className="council-member">
                    <div className="member-img">
                        <img src={PresidingBishop} alt="" />
                    </div>
                    <div className="member-name-position">
                        <p className="member-position">
                            Presiding Bishop
                        </p>
                        <p className="member-name">
                            Edwin Amoakohene Amponsah
                        </p>
                    </div>
                </div>

                <div className="council-member">
                    <div className="member-img">
                        <img src={PresidingBishop} alt="" />
                    </div>
                    <div className="member-name-position">
                        <p className="member-position">
                            Presiding Bishop
                        </p>
                        <p className="member-name">
                            Edwin Amoakohene Amponsah
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Leadership;
