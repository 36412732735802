import axios from "axios";
import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { api_url } from "../../data";
import Swal from "sweetalert2";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

const AddChildModal = ({ modal, setModal, member_id }) => {
  const [addingChild, setAddingChild] = useState(false);
  const [data, setData] = useState();

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // ADD NEW CHILD
  const createUser = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      const body = JSON.stringify(data);
      try {
        setAddingChild(true);
        await axios
          .post(`${api_url}/members/single-member-child/${member_id}/`, body, config)
          .then(() => {
            setAddingChild(false);
            setModal(!modal);
            Swal.fire({
              title: "Success",
              text: "Child has been added successfully",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          });
      } catch (error) {
        console.log(error);
        setAddingChild(false);
        toast.error("There was an error in adding this child.");
      }
    }
  };

  return (
    <div className="modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Add Child</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => createUser(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Full Name <span className="text-danger">*</span></label>
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                name="name"
                id="name"
                placeholder="Full Name"
                required
              />
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Date of birth<span className="text-danger">*</span></label>
              <input
                type="date"
                name="date_of_birth"
                id="date_of_birth"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="modal-form-field">
              <label>Gender<span className="text-danger">*</span></label>
              <select
                required
                name="gender"
                id="gender"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Gender</option>
                <option
                  value="Male"
                  onChange={(e) => handleChange(e)}
                >
                  Male
                </option>
                <option
                  value="Female"
                  onChange={(e) => handleChange(e)}
                >
                  Female
                </option>
                
              </select>
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Relationship<span className="text-danger">*</span></label>
              <input
                type="text"
                name="relationship"
                id="relationship"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            
            {addingChild ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#030166"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <input type="submit" value="Add Child" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddChildModal;
