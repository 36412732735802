import axios from "axios";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../../data";
import "./AddUserModal.scss";

const AddUserModal = ({ modal, setModal, registeredMembers }) => {
  const [data, setData] = useState({});
  const [creatingAccount, setCreatingAccount] = useState(false)

  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // ADD NEW USER
  const createUser = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      const body = JSON.stringify(data);
      try {
        setCreatingAccount(true)
          await axios.post(`${api_url}/accounts/user/`,body, config).then(()=>{
            setCreatingAccount(false)
            setModal(!modal)
            Swal.fire({
                title:"Account Created",
                text:"Account has been created successfully",
                icon:"success"
            }).then(()=>{
                window.location.reload();
            })
          })
      } catch (error) {
        console.log(error);
        setCreatingAccount(false)
        toast.error("There was an error in creating this user account.")
      }
    }
  };
  return (
    <div className="modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Create User Account</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => createUser(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Username</label>
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                name="username"
                id="username"
                placeholder="Username"
                required
              />
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Password</label>
              <input
                type="text"
                name="password"
                id="password"
                placeholder="Password"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="modal-form-field">
              <label>Role</label>
              <select required name="role" id="role" onChange={(e) => handleChange(e)}>
                <option value="">Select Role</option>
                <option
                  value="Administrative Assistant"
                  onChange={(e) => handleChange(e)}
                >
                  Administrative Assistant
                </option>
                <option
                  value="Finance Manager"
                  onChange={(e) => handleChange(e)}
                >
                  Finance Manager
                </option>
                <option
                  value="Welfare Manager"
                  onChange={(e) => handleChange(e)}
                >
                  Welfare Manager
                </option>
              </select>
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Member</label>
              <select required name="member" id="" onChange={(e) => handleChange(e)}>
                <option value="">Select Member</option>
                {registeredMembers.map((member) => (
                  <option value={member.id} key={member.id}>
                    {member.first_name} {member.last_name}
                  </option>
                ))}
              </select>
            </div>
            {
                creatingAccount ? (
                    <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#030166"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ):(
                    <input type="submit" value="Create User" />
                )
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
