import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";

const MemberPayments = ({ incomeTypes }) => {
  const { member_id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const [memberPayments, setMemberPayments] = useState([]);
  const [gettingMemberPayments, setGettingMemberPayments] = useState(false);
  const [data, setData] = useState({
    member: member_id,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [recordingPayment, setRecordingPayment] = useState(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const recordMemberPayment = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setRecordingPayment(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      const body = JSON.stringify(data);

      try {
        await axios
          .post(`${api_url}/finance/member-payment/${member_id}/`, body, config)
          .then((response) => {
            if (response.status === 201) {
              setRecordingPayment(false);

              Swal.fire({
                title: "Payment Recorded",
                text: "Payment has been recorded successfully",
                icon: "success",
              }).then(() => {
                window.location.reload();
              });
            }
          });
      } catch (error) {
        setRecordingPayment(false);
        console.log(error);
        toast.error("An error occurred.");
      }
    }
  };

  const getMemberPayments = async () => {
    if (localStorage.getItem("access")) {
      setGettingMemberPayments(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };

      try {
        await axios
          .get(`${api_url}/finance/member-payment/${member_id}/`, config)
          .then((response) => {
            setMemberPayments(response.data);
            setGettingMemberPayments(false);
          });
      } catch (error) {
        setGettingMemberPayments(false);
        console.log(error);
        toast.error("An error occurred.");
      }
    }
  };

  // INITIALIZE DELETE MEMBER
  const initializeDeleteMemberPayment = (payment_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMemberPayment(payment_id);
        // console.log(payment_id)
      }
    });
  };

  // DELETE MEMBER
  const deleteMemberPayment = async (payment_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .delete(
            `${api_url}/finance/member-payment-details/${payment_id}/`,
            config
          )
          .then((response) => {
            if (response.status === 204) {
              toast.success("Member Payment deleted successfully");
              getMemberPayments();
            }
          });
      } catch (error) {
        console.log(error);
        toast.error(
          "Error while deleting member payment, please try again later"
        );
      }
    }
  };

  // FILTER MEMBER PAYMENTS
  const filterMemberPayments = async () => {
    
    setGettingMemberPayments(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    };

    try {
      await axios
        .get(
          `${api_url}/finance/member-payment/${member_id}/${startDate}/${endDate}/`,
          config
        )
        .then((response) => {
          setMemberPayments(response.data);
          setGettingMemberPayments(false);
        });
    } catch (error) {
      setGettingMemberPayments(false);
      toast.error(
        "Error while fetching member payments, please try again later"
      );
    }
    
  }

  useEffect(() => {
    getMemberPayments();
  }, []);

  return (
    <div id="member-management" style={{paddingTop:"20px"}}>
      <div className="member-management-top">
        <div className="member-management-tabs">
          <div
            onClick={() => setActiveTab(1)}
            className={`${
              activeTab === 1
                ? "member-management-tab active-tab"
                : "member-management-tab"
            }`}
          >
            Payment List
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className={`${
              activeTab === 2
                ? "member-management-tab active-tab"
                : "member-management-tab"
            }`}
          >
            Record Payment
          </div>
        </div>
      </div>
      {activeTab === 1 && (
        <div className="table-container">
          <div className="table-body">
            <div className="table-top">
              <div className="table--header">
                <form className="filter-form w-100">
                  <div className="custom--row">
                    <div className="input-date-group">
                      <label htmlFor="start_date" className="input-label">
                        Start Date
                      </label>
                      <div className="w-100">
                        <input
                          type="date"
                          name=""
                          id="start_date"
                          className="form--control m-h-45"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="input-date-group">
                      <label htmlFor="end_date" className="input-label">
                        End Date
                      </label>
                      <div className="w-100">
                        <input
                          type="date"
                          name=""
                          id="start_date"
                          className="form--control m-h-45"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <div onClick={()=>filterMemberPayments()} className="default-button">Filter</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="default-table" style={{ marginTop: "20px" }}>
              <table>
                <thead>
                  <tr>
                    <th>Payment ID</th>
                    <th>Payment Type</th>
                    <th>Date Paid</th>
                    <th>Amount Paid</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {memberPayments.map((memberPayment) => (
                    <tr>
                      <td>{memberPayment.payment_id}</td>
                      <td>{memberPayment.payment_type}</td>
                      <td>{memberPayment.date}</td>
                      <td>{memberPayment.amount}</td>
                      <td className="actions">
                        <Link className="danger-btn ">
                          <AiOutlineDelete
                            onClick={() =>
                              initializeDeleteMemberPayment(memberPayment.id)
                            }
                          />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {memberPayments.length <= 0 && (
                <div className="no-data">
                  <img
                    src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?size=626&ext=jpg&ga=GA1.1.455008150.1699805477&semt=ais"
                    alt=""
                  />
                  No data found
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {activeTab === 2 && (
        <div className="new-modal-overlay">
          <div className="new-modal-container">
            <div className="modal-body">
              <form
                onSubmit={(e) => recordMemberPayment(e)}
                className="add-user-form"
              >
                <div className="modal-form-field">
                  <label htmlFor="">Payment Type</label>
                  <select
                    name="payment_type"
                    id=""
                    onChange={(e) => handleChange(e)}
                    required
                  >
                    <option value="">Select Payment Type</option>
                    {incomeTypes?.map((incomeType) => (
                      <option
                        selected={incomeType.id === data?.income_type}
                        value={incomeType.id}
                        key={incomeType.id}
                      >
                        {incomeType.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="modal-form-field">
                  <label htmlFor="">Date</label>
                  <input
                    type="date"
                    name="date"
                    onChange={(e) => handleChange(e)}
                    required
                    id=""
                  />
                </div>
                <div className="modal-form-field">
                  <label htmlFor="">Amount</label>
                  <input
                    type="number"
                    name="amount"
                    onChange={(e) => handleChange(e)}
                    required
                    step="0.01"
                    id=""
                  />
                </div>
                <div className="modal-form-field">
                  <label>Payment Method</label>
                  <select
                    required
                    name="payment_method"
                    id="payment_method"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Payment Method</option>
                    <option value="Cash" onChange={(e) => handleChange(e)}>
                      Cash
                    </option>
                    <option
                      value="Mobile Money"
                      onChange={(e) => handleChange(e)}
                    >
                      Mobile Money
                    </option>
                    <option
                      value="Bank Transfer"
                      onChange={(e) => handleChange(e)}
                    >
                      Bank Transfer
                    </option>
                  </select>
                </div>
                {recordingPayment ? (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#030166"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <input type="submit" value="Record Payment" />
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberPayments;
