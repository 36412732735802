import {useState, useEffect, useRef} from 'react'
import './Timer.scss'

const Timer = ({date}) => {
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);
 
    const [days, setDays] = useState('00');
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');

 
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        return {
            total, hours, minutes, seconds, days
        };
    }

    const startTimer = (e) => {
        let { total, hours, minutes, seconds, days } 
                    = getTimeRemaining(e);
        if (total >= 0) {
 
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setDays(days);
            setHours(hours > 9 ? hours : "0"+hours);
            setMinutes(minutes > 9 ? minutes : "0"+minutes);
            setSeconds(seconds > 9 ? seconds : "0"+seconds);
        }
    }
 
    const clearTimer = (e) => {
        setDays("00")
        setHours("00")
        setMinutes("00")
        setSeconds("00")
        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
 
    const getDeadTime = () => {
        let deadline = new Date("2023-10-24T10:24:00");
 
        // This is where you need to adjust if 
        // you entend to add more time
        // deadline.setSeconds(deadline.getSeconds() + 10);
        return deadline;
    }
 
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
 
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);
 
    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button

  return (
    <div className='timer'>
        <div className="timer-section">
            <h3>{days}</h3>
            <p>DAYS</p>
        </div>
        <div className="timer-separator">:</div>
        <div className="timer-section">
            <h3>{hours}</h3>
            <p>HOURS</p>
        </div>
        <div className="timer-separator">:</div>
        <div className="timer-section">
            <h3>{minutes}</h3>
            <p>MINUTES</p>
        </div>
        <div className="timer-separator">:</div>
        <div className="timer-section">
            <h3>{seconds}</h3>
            <p>SECONDS</p>
        </div>
    </div>
  )
}

export default Timer