import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './pages/Login/Login';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from './utils/Layout';
import PrivateRoutes from './utils/PrivateRoutes';
import { AuthProvider } from './context/AuthContext';
import HomePage from './WebsitePages/HomePage/HomePage';
import AboutPage from './WebsitePages/AboutPage/AboutPage';
import Leadership from './WebsitePages/Leadership/Leadership';
import Departments from './WebsitePages/Departments/Departments';
import Resources from './WebsitePages/Resources/Resources';
import ContactUs from './WebsitePages/ContactUs/ContactUs';

function App() {
  return (
    <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path='/*' element={<PrivateRoutes component={Layout} />} />
        <Route path='' exact element={<Login />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/leadership" element={<Leadership />} />
        <Route path="/departments" element={<Departments />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </AuthProvider>
      <ToastContainer
          position="top-right"
          autoClose={6608}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
    </BrowserRouter>
  );
}

export default App;
