import React, {useContext} from 'react'
import './Topbar.scss'
import AuthContext from '../../context/AuthContext'
import { IoMdMenu } from "react-icons/io";


const Topbar = ({title}) => {
  const {user,openSidebar, setOpenSidebar} = useContext(AuthContext)
  
  
  
  return (
    <div id='topbar'>
        <div className="topbar-title">
            <span className='menu-icon'>
              <IoMdMenu onClick={()=>setOpenSidebar(!openSidebar)} />
            </span>
            {title}
        </div>
        <div className="topbar-info">
            {/* <GrNotification /> */}
            <div className="user-info">
              <p>{user?.username}</p>
              <p style={{color:"gray",fontSize:"14px"}}>{user?.role}</p>
            </div>
            <div className="avatar-image">
                <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80' alt='user'/>
            </div>
        </div>
    </div>
  )
}

export default Topbar