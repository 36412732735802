import axios from "axios"
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { api_url } from "../data";


const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const [authenticating, setAuthenticating] = useState(false)
    const [user, setUser] = useState(null)
    const [loggingIn, setLoggingIn] = useState(false)
    const [openSidebar, setOpenSidebar] = useState(true);

    const navigate = useNavigate

    const loginUser = async(e) => {
        e.preventDefault()
        const username = e.target.username.value
        const password = e.target.password.value
        
        if (username === "" || password === "" ){
            toast.error("Username and password are required")
        }else{
            setLoggingIn(true)
            const config = {
                headers:{
                    "Content-Type": "application/json"
                }
            }
            const body = JSON.stringify({
                username: username,
                password: password
            })
            try {
                await axios.post(`${api_url}/auth/token/`, body, config).then((response)=>{
                    localStorage.setItem("access", response.data['access'])
                    localStorage.setItem("refresh", response.data['refresh'])
                    
                    setLoggingIn(false)
                    window.location.href = "/dashboard"
                    // navigate("/dashboard")
                    toast.success("logged in")
                })
            } catch (error) {
                toast.error("Invalid Username and password.")
                setLoggingIn(false)
                setUser(null)
            }
        }
    }

    const getUser = async() => {
        if(localStorage.getItem("access")){
            const config = {
                headers:{
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access")
                }
            }
            try {
                await axios.get(`${api_url}/accounts/users/me/`, config).then((response)=>{
                    setUser(response.data)
                    setAuthenticating(false)
                })
            } catch (error) {
                setUser(null)                
                setAuthenticating(false)
            }
        }
    }

    const logoutUser = () => {
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        setUser(null)
        window.location.href = "/"
    }

    useEffect(()=>{
        getUser()
    },[])

    const contextData = {
        authenticating,
        user,
        loggingIn,
        loginUser,
        logoutUser,
        openSidebar,
        setOpenSidebar
    }
    if(authenticating){
        return (
            <>Authenticating</>
        )
    }else{
        return (
            <AuthContext.Provider value={contextData}>
                {children}
            </AuthContext.Provider>
        )
    }
}

export default AuthContext;