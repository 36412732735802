import React from 'react'
import { NavLink } from 'react-router-dom'

const NavItem = ({item}) => {
  return (
    <li className='navigation-item'>
        <NavLink to={item.path} className="nav-link-item">
            {item.name}
        </NavLink>
    </li>
  )
}

export default NavItem