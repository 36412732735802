import axios from "axios";
import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { api_url } from "../../data";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ChurchSms = ({churchDetails}) => {
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState({
    message: "",
  });
  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // SUBMIT FORM
  const submitForm = async (e) => {
    e.preventDefault();
    if(data?.message.length > 160){
        toast.error("Message is more than 160 characters")
        return false;
    }
    if (localStorage.getItem("access")) {
      setSubmitting(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      
      const body = JSON.stringify(data);

      try {
        await axios
          .post(`${api_url}/accounts/send-sms/`, body, config)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "SMS sent successfully",
              }).then(() => {
                window.location.reload();
              });
              setSubmitting(false);
            }
          });
      } catch (error) {
        console.log(error);
        setSubmitting(false);
        toast.error("An error occurred.");
      }
    }
  };

  return (
    <div className="settings-details">
      <h3>SMS CENTER</h3>
      <p>Send bulk SMS to members and visitors</p>
      <form onSubmit={(e)=>submitForm(e)} className="church-form">
        <div className="form-field">
          <label htmlFor="">Recipients</label>
          <select name="recipients" onChange={(e) => handleChange(e)} id="">
            <option value="">Select Recipeint</option>
            <option value="Members">Members</option>
            <option value="Visitors">Visitors</option>
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="">Message</label>{" "}
          <small>(should not be more than 160 words)</small>
          <textarea
            name="message"
            onChange={(e) => handleChange(e)}
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <small>{data?.message.length} words</small>
        </div>
        {submitting ? (
          <div className="continue-button">
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#030166"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <input type="submit" value="Send SMS" className="default-button" />
        )}
      </form>
        
        <div style={{marginTop:'20px'}}>
            <strong >
                NB: You have {churchDetails?.sms_bundle} left!
            </strong>
        </div>
    </div>
  );
};

export default ChurchSms;
