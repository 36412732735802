import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../../data";
import axios from "axios";

const EditUserModal = ({ modal, setModal, registeredMembers, userDetails }) => {
  const [data, setData] = useState(userDetails);
  const [updatingDetails, setUpdatingDetails] = useState(false);
  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  //   UPDATE DETAILS
  const updateDetails = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      const body = JSON.stringify(data);
      try {
        setUpdatingDetails(true);
        await axios
          .put(`${api_url}/accounts/user/${data?.id}/`, body, config)
          .then(() => {
            setUpdatingDetails(false);
            setModal(!modal);
            Swal.fire({
              title: "Account Updated",
              text: "Account has been updated successfully",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          });
      } catch (error) {
        console.log(error);
        setUpdatingDetails(false);
        toast.error("There was an error in updating this user account.");
      }
    }
  };

  

  return (
    <div className="modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>User Account</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => updateDetails(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Username</label>
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                name="username"
                id="username"
                placeholder="Username"
                value={data?.username}
                required
              />
            </div>

            <div className="modal-form-field">
              <label>Role</label>
              <select
                required
                name="role"
                id="role"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Role</option>
                <option
                  value="Administrator"
                  onChange={(e) => handleChange(e)}
                  selected={data?.role === "Administrator"}
                >
                  Administrator
                </option>
                <option
                  value="Administrative Assistant"
                  onChange={(e) => handleChange(e)}
                  selected={data?.role === "Administrative Assistant"}
                >
                  Administrative Assistant
                </option>
                <option
                  value="Finance Manager"
                  onChange={(e) => handleChange(e)}
                  selected={data?.role === "Finance Manager"}
                >
                  Finance Manager
                </option>
                <option
                  value="Welfare Manager"
                  onChange={(e) => handleChange(e)}
                  selected={data?.role === "Welfare Manager"}
                >
                  Welfare Manager
                </option>
              </select>
            </div>

            <div className="modal-form-field">
              <label htmlFor="">Member</label>
              <select
                required
                name="member"
                id=""
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Member</option>
                {registeredMembers.map((member) => (
                  <option
                    selected={data?.member === member.id}
                    value={data?.member ? data?.member : member.id}
                    key={member.id}
                  >
                    {member.first_name} {member.last_name}
                  </option>
                ))}
              </select>
            </div>
            {updatingDetails ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#030166"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <input type="submit" value="Update User" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
