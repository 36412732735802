import React from 'react'
import './Departments.scss';
import Navbar from '../../WebsiteComponents/Navbar/Navbar'
import Footer from '../../WebsiteComponents/Footer/Footer';

const Departments = () => {
  return (
    <div>
        <Navbar />
        <div id="department-page">
            <div className="departments">
                <div className="department">
                    <p className="department-title">REC MINISTRIES</p>
                    <p className="department-name">
                        Ushering Department
                    </p>
                </div>
                <div className="department">
                    <p className="department-title">REC MINISTRIES</p>
                    <p className="department-name">
                        Ushering Department
                    </p>
                </div>
                <div className="department">
                    <p className="department-title">REC MINISTRIES</p>
                    <p className="department-name">
                        Ushering Department
                    </p>
                </div>
                <div className="department">
                    <p className="department-title">REC MINISTRIES</p>
                    <p className="department-name">
                        Ushering Department
                    </p>
                </div>
                <div className="department">
                    <p className="department-title">REC MINISTRIES</p>
                    <p className="department-name">
                        Ushering Department
                    </p>
                </div>
                <div className="department">
                    <p className="department-title">REC MINISTRIES</p>
                    <p className="department-name">
                        Ushering Department
                    </p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Departments