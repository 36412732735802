import React, { useState } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";
import axios from "axios";
import EditIncomeModal from "./EditIncomeModal";

const IncomeList = ({ incomeTypes, modal, setModal, incomeList }) => {
  const [selectedIncome, setSelectedIncome] = useState(null)
  const [editIncomeModal, setEditIncomeModal] = useState(false)

  const editIncome = (income)=>{
    setSelectedIncome(income)
    setEditIncomeModal(!editIncomeModal)
  }

  // INITIALIZE DELETE INCOME ACCOUNT
  const initializeDeleteIncome = (income_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once you delete this income, you cannot revert it.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteIncome(income_id);
      }
    });
  };

  // DELETE INCOME ACCOUNT
  const deleteIncome = async (income_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .delete(`${api_url}/finance/income/${income_id}/`, config)
          .then((response) => {
            if (response.status === 204) {
              toast.success("Income deleted successfully");
              window.location.reload();
            } else {
              toast.error("Something went wrong please try again");
            }
          });
      } catch (error) {
        console.log(error);
        toast.error(
          "Error while deleting user account, please try again later"
        );
      }
    }
  };

  return (

      <div className="income-page">
        {
          editIncomeModal && <EditIncomeModal incomeTypes={incomeTypes} modal = {editIncomeModal} setModal = {setEditIncomeModal} incomeDetails={selectedIncome}  />
        }
        <div className="add-income">
          <div className="default-button" onClick={() => setModal(!modal)}>
            Record Income
          </div>
        </div>
        <div className="default-table">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Income ID</th>
                <th>Amount</th>
                <th>Paid By</th>
                <th>Income Type</th>
                <th>Payment Method</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {incomeList.map((income, index) => (
                <tr key={index}>
                  <td>{income.date_received}</td>
                  <td>#{income.income_id}</td>
                  <td>GHS {income.amount_received}</td>
                  <td>{income.payer_name}</td>
                  <td>{income.income_type.name}</td>
                  <td>{income.payment_method}</td>
                  <td className="actions">
                    <AiOutlineEdit onClick={()=>editIncome(income)} />
                    <AiOutlineDelete onClick={()=>initializeDeleteIncome(income.id)}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    
  );
};

export default IncomeList;
