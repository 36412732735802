import React, {useContext, useState} from 'react'
import { toast } from 'react-toastify'
import ChurchLogo from "../../assets/ChurchLogo.png"
import AuthContext from '../../context/AuthContext'
import Swal from "sweetalert2";
import axios from "axios";
import { api_url } from "../../data";
import { ThreeDots } from "react-loader-spinner";


const PersonalSettings = () => {
    const {user} = useContext(AuthContext)
    const [submitting, setSubmitting] = useState(false)
    const [newPassword, setNewPassword] = useState(null)
    const [newPasswordConfirm, setNewPasswordConfirm] = useState(null)

     // SUBMIT FORM
  const resetPassword = async (e) => {
    e.preventDefault()
    if(newPassword !== null && newPassword === newPasswordConfirm){
        if(localStorage.getItem("access")){
          setSubmitting(true)
          const config = {
            headers:{
              "Content-Type": "application/json",
              Authorization:"Bearer " + localStorage.getItem("access")
            }
          }
          
          const body = JSON.stringify(
            {
                "new_password":newPassword
            }
          )
          
          try {
            await axios.put(`${api_url}/accounts/user/password/reset/`, body, config).then((response)=>{
              if(response.status === 200){
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Password Changed Successfully",
                }).then(()=>{
                    window.location.reload();
                });
                setSubmitting(false)
              }
            })
          } catch (error) {
            console.log(error)
            setSubmitting(false)
            toast.error("An error occurred.")
          }
        }
    }else{
        toast.error("Passwords do not match")
    }
  };

  return (
    <div className='settings-details'>
        <h3>Profile</h3>
        <p>
            Update your profile details here.
        </p>
        <form action="" className="church-form">
            
            <div className="profile-picture-wrapper">
                <img src={user?.profile_picture ? user?.profile_picture : ChurchLogo} alt="" className="church-logo" />
                <div className="picture-details">
                    <h3>Profile Picture</h3>
                    {/* <label htmlFor="image-upload" className='upload-button'>
                        Upload Image
                    </label>
                    <input type="file" name="Upload Image" style={{display:"none"}} id="image-upload" />
                     */}
                    <p className="info">
                        To change your profile picture, please contact the administrator
                    </p>
                </div>
            </div>
            <div className="form-field-row">
                <div className="form-field">
                    <label htmlFor="">First Name</label>
                    <input type="text" disabled value={user?.first_name} name="first_name" id="" />
                </div>
                <div className="form-field">
                    <label htmlFor="">Last Name</label>
                    <input type="text" disabled value={user?.last_name} name="last_name" id="" />
                </div>
                <div className="form-field">
                    <label htmlFor="">Username</label>
                    <input type="text" disabled value={user?.username} name="username" id="" />
                </div>
            </div>
            
            
            {/* <input type="submit" value="Save Changes" className="default-button" /> */}
        </form>
        <hr style={{marginTop: '10px'}} />
        <section className="change-password">
            <form onSubmit={e=>resetPassword(e)} className="church-form">
            <div className="form-field-row">
                <div className="form-field">
                    <label htmlFor="">New Password</label>
                    <input type="text" onChange={e=>setNewPassword(e.target.value)} name="new_password" id="" />
                </div>
                <div className="form-field">
                    <label htmlFor="">Confirm New Password</label>
                    <input type="text"  onChange={e=>setNewPasswordConfirm(e.target.value)} name="new_password_confirm" id="" />
                </div>
            </div>
            {
                submitting ? (
                    <div className="continue-button">
                        <ThreeDots
                        height="40"
                        width="40"
                        radius="9"
                        color="#030166"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                ):(
                    <input type="submit" value="Change Password" className="default-button" />
                )
            }
            </form>
        </section>
    </div>
  )
}

export default PersonalSettings