import axios from "axios";
import React, { useContext, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";
import AuthContext from "../../context/AuthContext";

const AddIncomeModal = ({ incomeTypes, modal, setModal,getIncomeList }) => {
  const {user} = useContext(AuthContext)
  const [data, setData] = useState({});
    const [recordingIncome, setRecordingIncome] = useState(false)

  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

//   RECORD INCOME
  const recordIncome = async (e) => {
    e.preventDefault()
    if(localStorage.getItem("access")){
        setRecordingIncome(true)
        const config = {
            headers:{
                "Content-Type": "application/json",
                Authorization:"Bearer " + localStorage.getItem("access"),
            }
        }
        const body = JSON.stringify(data)
        
        try {
            await axios.post(`${api_url}/finance/income/`,body, config).then((response) => {
                setModal(!modal)
                setRecordingIncome(false)
                getIncomeList()
                Swal.fire({
                    title:"Income Recorded",
                    text:"Income has been recorded successfully",
                    icon:"success"
                }).then(()=>{
                  window.location.reload()
                })
    
            })
            
        } catch (error) {
            setRecordingIncome(false)
            console.log(error)
            toast.error("An error occurred.")    
        }
    }
  }
  return (
    <div className="modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Record Income</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e)=>recordIncome(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Date</label>
              <input
                type="date"
                onChange={(e) => handleChange(e)}
                name="date_received"
                id="date_received"
                required
              />
            </div>
            <div className="modal-form-field">
              <label>Income Type</label>
              <select
                required
                name="income_type"
                id="income_type"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Income Type</option>
                {
                  incomeTypes?.map((incomeType)=>(
                    <option value={incomeType.id} key={incomeType.id}>
                      {incomeType.name}
                    </option>
                  ))
                }
                {
                  user?.role !== "Welfare Manager" && (
                    <option value = "Other">Other</option>
                  )
                }
              </select>
            </div>
            {
              data?.income_type === "Other" && (
                <div className="modal-form-field">
                  <label htmlFor="">New Income Type</label>
                  <input
                    type="text"
                    name="new_income_type"
                    id="new_income_type"
                    placeholder="Enter new income type name"
                    onChange={(e) => handleChange(e)}
                    required={data?.income_type === "Other"}
                  />
                </div>    
              )
            }
            <div className="modal-form-field">
              <label htmlFor="">Amount Received</label>
              <input
                type="number"
                name="amount_received"
                id="amount_received"
                placeholder="Amount Received"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Payer Name</label>
              <input
                type="text"
                name="payer_name"
                id="payer_name"
                placeholder="Payer Name"
                onChange={(e) => handleChange(e)}
                
              />
            </div>
            <div className="modal-form-field">
              <label>Payment Method</label>
              <select
                required
                name="payment_method"
                id="payment_method"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Payment Method</option>
                <option value="Cash" onChange={(e) => handleChange(e)}>
                  Cash
                </option>
                <option value="Mobile Money" onChange={(e) => handleChange(e)}>
                  Mobile Money
                </option>
                <option value="Bank Transfer" onChange={(e) => handleChange(e)}>
                  Bank Transfer
                </option>
              </select>
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Description</label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="5"
                required
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>
            {
                recordingIncome ? (
                    <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#030166"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ):(
                    <input type="submit" value="Record Income" />
                )
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddIncomeModal;
