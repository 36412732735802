import { faker } from "@faker-js/faker";

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const pieChartLabels = ["Income", "Expense"];


export const expenseData = {
  labels: labels,
  datasets: [
    {
      label: "Expense",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: "#FF6969",
    },
  ],
};

export const incomeData = {
  labels: labels,
  datasets: [
    {
      label: "Income",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: "#79AC78",
    },
  ],
};

export const pieChartData = {
  labels: pieChartLabels,
  datasets: [
    {
      label: "Total",
      data: pieChartLabels.map(() =>
        faker.datatype.number({ min: 0, max: 100 })
      ),
      backgroundColor: ["#599BF2", "#9747FF"],
    },
  ],
};

// export const api_url = "http://localhost:8000"
export const api_url = "https://api.royalevangelicalchurchksi.com"
