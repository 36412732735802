import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import MemberDetails from "./MemberDetails";
import axios from "axios"
import { api_url } from "../../data";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import MemberPayments from "./MemberPayments";

const MemberProfile = () => {
    const [activeTab, setActiveTab] = useState(1);
    const {member_id} = useParams();
    const [dataFetched, setDataFetched] = useState(false);
    const [memberDetails, setMemberDetails] = useState(null);
    const [memberChildren, setMemberChildren] = useState([])
    const [incomeTypes, setIncomeTypes] = useState([])

  // GET EXPENSE TYPES
  const getIncomeTypes = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .get(`${api_url}/finance/income-types/`, config)
          .then((response) => {
            if (response.status === 200) {
              setIncomeTypes(response.data)
            }
          });
      } catch (error) {
      }
    }
  }


    const getMemberDetails = async (member_id) => {
        if(localStorage.getItem("access")){
            setDataFetched(false)
            const config = {
              headers:{
                "Content-Type": "multipart/form-data",
                Authorization:"Bearer " + localStorage.getItem("access")
              }
            }
            
            try {
              await axios.get(`${api_url}/members/${member_id}`,  config).then((response)=>{
                setMemberDetails(response.data)
                axios.get(`${api_url}/members/member-child/${member_id}`, config).then((response)=>{
                    setMemberChildren(response.data)
                    console.log(response.data)
                    setDataFetched(true)
                }).catch((error)=>{
                    toast.error("Something went wrong.")
                    setDataFetched(true)
                })
                
              })
            } catch (error) {
              
              setDataFetched(true)
              toast.error("An error occurred.")
            }
          }
    }

    useEffect(() => {
        getMemberDetails(member_id)
        getIncomeTypes()
      
    }, [])
    
    if(dataFetched){
        return (
          <div id="main-layout">
            <Sidebar />
            <div id="main-page">
              <Topbar title="Member Profile" />
              <div id="member-management">
                <div className="member-management-top">
                  <div className="member-management-tabs">
                    <div
                      onClick={() => setActiveTab(1)}
                      className={`${
                        activeTab === 1
                          ? "member-management-tab active-tab"
                          : "member-management-tab"
                      }`}
                    >
                      Profile
                    </div>
                    {
                      memberDetails?.is_permanent_member && (
                        <div
                          onClick={() => setActiveTab(2)}
                          className={`${
                            activeTab === 2
                              ? "member-management-tab active-tab"
                              : "member-management-tab"
                          }`}
                        >
                          Member Payments
                        </div>
                      )
                    }
                  </div>
                </div>
                  {
                      activeTab === 1 && (
                          <MemberDetails memberChildren={memberChildren} memberDetails={memberDetails} />
                      )
                  }
                  {
                    activeTab === 2 && (
                      <MemberPayments incomeTypes={incomeTypes} memberId={member_id} />
                    )
                  }
              </div>
            </div>
          </div>
        );
    }else{
        return (
            <Loading />
        )
    }
};

export default MemberProfile;
