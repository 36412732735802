import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";

const MemberDetails2 = ({updateData, setPage, data, handleChange, updateDetails }) => {
  const {user} = useContext(AuthContext)
  return (
    <div className="add-member-form">
      {/* FAMILY INFORMATION (CONT'D) */}
      <div className="member-form-section">
        <div className="member-form-section-header">
          FAMILY INFORMATION (CONT’D)
        </div>
        <div className="member-form-body">
          <div className="member-form-details">
            {/* FIRST ROW */}
            <div className="member-form-row">
              {/* FATHER'S NAME */}
              <div className="member-form-column">
                <label htmlFor="">Father's Name</label>
                <input
                  type="text"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.father_name ? updateData?.father_name:data?.father_name}
                  name="father_name"
                  id="father_name"
                />
              </div>
              {/* FATHER'S CONTACT NUMBER */}
              <div className="member-form-column">
                <label htmlFor="">Father's Contact Number</label>
                <input
                  type="text"
                  name="father_contact_number"
                  id="father_contact_number"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.father_contact_number ? updateData?.father_contact_number:data?.father_contact_number}
                />
              </div>
              {/* ADDRESS */}
              <div className="member-form-column">
                <label htmlFor="">Address</label>
                <input
                  type="text"
                  name="father_address"
                  id="father_address"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.father_address ? updateData?.father_address:data?.father_address}
                />
              </div>
            </div>
            {/* SECOND ROW */}
            <div className="member-form-row">
              {/* FATHER OCCUPATION */}
              <div className="member-form-column">
                <label htmlFor="">Father's Occupation</label>
                <input
                  type="text"
                  name="father_occupation"
                  id="father_occupation"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.father_occupation ? updateData?.father_occupation:data?.father_occupation}
                />
              </div>
              {/* FATHER HOMETOWN */}
              <div className="member-form-column">
                <label htmlFor="">Father's Hometown</label>
                <input
                  type="text"
                  name="father_hometown"
                  id="father_hometown"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.father_hometown ? updateData?.father_hometown:data?.father_hometown}
                />
              </div>
              {/* FATHER NATIONALITY */}
              <div className="member-form-column">
                <label htmlFor="">Father's Nationality</label>
                <input
                  type="text"
                  name="father_nationality"
                  id="father_nationality"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.father_nationality ? updateData?.father_nationality:data?.father_nationality}
                />
              </div>
            </div>
            {/* THIRD ROW */}
            <div className="member-form-row">
              {/* MOTHER'S NAME */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Name</label>
                <input
                  type="text"
                  name="mother_name"
                  id="mother_name"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.mother_name ? updateData?.mother_name:data?.mother_name}
                />
              </div>
              {/* MOTHER'S CONTACT NUMBER */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Contact Number</label>
                <input
                  type="text"
                  name="mother_contact_number"
                  id="mother_contact_number"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.mother_contact_number ? updateData?.mother_contact_number:data?.mother_contact_number}
                />
              </div>
              {/* MOTHER'S ADDRESS */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Address</label>
                <input
                  type="text"
                  name="mother_address"
                  id="mother_address"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.mother_address ? updateData?.mother_address:data?.mother_address}
                />
              </div>
            </div>
            {/* FOURTH ROW */}
            <div className="member-form-row">
              {/* MOTHER'S OCCUPATION */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Occupation</label>
                <input
                  type="text"
                  name="mother_occupation"
                  id="mother_occupation"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.mother_occupation ? updateData?.mother_occupation:data?.mother_occupation}
                />
              </div>
              {/* MOTHER'S HOMETOWN */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Hometown</label>
                <input
                  type="text"
                  name="mother_hometown"
                  id="mother_hometown"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.mother_hometown ? updateData?.mother_hometown:data?.mother_hometown}
                />
              </div>
              {/* MOTHER'S NATIONALITY */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Nationality</label>
                <input
                  type="text"
                  name="mother_nationality"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.mother_nationality ? updateData?.mother_nationality:data?.mother_nationality}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EDUCATION & WORK INFORMATION */}
      <div className="member-form-section">
        <div className="member-form-section-header">
          EDUCATION & WORK INFORMATION
        </div>
        <div className="member-form-body">
          <div className="member-form-details">
            {/* FIRST ROW */}
            <div className="member-form-row">
              {/* HIGHEST EDUCATION QUALIFICATION */}
              <div className="member-form-column">
                <label htmlFor="">Highest Education Qualification</label>
                <input
                  type="text"
                  name="education_qualification"
                  id="education_qualification"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.education_qualification ? updateData?.education_qualification:data?.education_qualification}
                />
              </div>
            </div>
            {/* SECOND ROW */}
            <div className="member-form-row">
              {/* PROFESSION */}
              <div className="member-form-column">
                <label htmlFor="">Profession</label>
                <input
                  type="text"
                  name="profession"
                  id="profession"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.profession ? updateData?.profession:data?.profession}
                />
              </div>
              {/* OCCUPATION */}
              <div className="member-form-column">
                <label htmlFor="">Occupation</label>
                <input
                  type="text"
                  name="occupation"
                  id="occupation"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.occupation ? updateData?.occupation:data?.occupation}
                />
              </div>
            </div>
            {/* THIRD ROW */}
            <div className="member-form-row">
              {/* PLACE OF WORK */}
              <div className="member-form-column">
                <label htmlFor="">Place of work</label>
                <input
                  type="text"
                  name="place_of_work"
                  id="place_of_work"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.place_of_work ? updateData?.place_of_work:data?.place_of_work}
                />
              </div>
              {/* CONTACT NUMBER */}
              <div className="member-form-column">
                <label htmlFor="">Contact Number</label>
                <input
                  type="text"
                  name="work_contact"
                  id="work_contact"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.work_contact ? updateData?.work_contact:data?.work_contact}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GENERAL INFORMATION */}
      <div className="member-form-section">
        <div className="member-form-section-header">GENERAL INFORMATION</div>
        <div className="member-form-body">
          <div className="member-form-details">
            {/* FIRST ROW */}
            <div className="member-form-row">
              {/* NAME OF SPOUSE */}
              <div className="member-form-column">
                <label htmlFor="">Next of Kin</label>
                <input
                  type="text"
                  name="next_of_kin"
                  id="next_of_kin"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.next_of_kin ? updateData?.next_of_kin:data?.next_of_kin}
                />
              </div>
              {/*RELATIONSHIP */}
              <div className="member-form-column">
                <label htmlFor="">Relationship</label>
                <input
                  type="text"
                  name="relationship"
                  id="relationship"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.next_of_kin ? updateData?.next_of_kin:data?.relationship}
                />
              </div>
            </div>
            {/* SECOND ROW */}
            <div className="member-form-row">
              {/*NOK ADDRESS*/}
              <div className="member-form-column">
                <label htmlFor="">Next of Kin Address</label>
                <input
                  type="text"
                  name="address_of_nok"
                  id="address_of_nok"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.address_of_nok ? updateData?.address_of_nok:data?.address_of_nok}
                />
              </div>
              {/* NOK CONTACT NUMBER */}
              <div className="member-form-column">
                <label htmlFor="">Next Of Kin Contact Number</label>
                <input
                  type="text"
                  name="nok_contact_number"
                  id="nok_contact_number"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.nok_contact_number ? updateData?.nok_contact_number:data?.nok_contact_number}
                />
              </div>
            </div>
            {/* THIRD ROW */}
            <div className="member-form-row">
              {/* EMERGENCY CONTACT PERSON */}
              <div className="member-form-column">
                <label htmlFor="">Emergency Contact Person</label>
                <input
                  type="text"
                  name="emergency_contact_person"
                  id="emergency_contact_person"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.emergency_contact_person ? updateData?.emergency_contact_person:data?.emergency_contact_person}
                />
              </div>
              {/* EMERGENCY CONTACT PERSON'S RELATIONSHIP*/}
              <div className="member-form-column">
                <label htmlFor="">
                  Emergency Contact Person's Relationship
                </label>
                <input
                  type="text"
                  name="emergency_person_relationship"
                  id="emergency_person_relationship"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.emergency_person_relationship ? updateData?.emergency_person_relationship:data?.emergency_person_relationship}
                />
              </div>
            </div>
            {/* FOURTH ROW */}
            <div className="member-form-row">
              {/* EMERGENCY CONTACT PERSON ADDRESS */}
              <div className="member-form-column">
                <label htmlFor="">Emergency Contact Person's Address</label>
                <input
                  type="text"
                  name="emergency_contact_person_address"
                  id="emergency_contact_person_address"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.emergency_contact_person_address ? updateData?.emergency_contact_person_address:data?.emergency_contact_person_address}
                />
              </div>
              {/* EMERGENCY CONTACT PERSON'S CONTACT*/}
              <div className="member-form-column">
                <label htmlFor=""> Contact Number</label>
                <input
                  type="text"
                  name="emergency_person_contact"
                  id="emergency_person_contact"
                  onChange={(e) => handleChange(e)}
                  value={updateData?.emergency_person_contact ? updateData?.emergency_person_contact:data?.emergency_person_contact}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="continue-button">
        <div className="default-button" onClick={() => setPage(1)}>
          Previous
        </div>
        {
          user?.role === "Administrator" && (
            <div className="default-button" onClick={updateDetails}>Update Details</div>
          )
        }
      </div>
    </div>
  );
};

export default MemberDetails2;
