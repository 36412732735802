import React,{useEffect} from "react";
import "./Login.scss";
import ChurchLogo from "../../assets/ChurchLogo.png";
import { Link } from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { toast } from "react-toastify";


const Login = () => {
  const {loginUser, user, loggingIn} = useContext(AuthContext)
  useEffect(()=>{
    if(user){
      toast.success("Redirecting")
      window.location.href="/dashboard"
    }
  },[user])

  
  return (
    <div id="login">
      <div className="left-login"></div>
      <div className="right-login">
        <div className="right-login-container">
          <div className="logo">
            <img src={ChurchLogo} alt="Royal" />
          </div>
          <h1 className="login-text">Login Account</h1>
          <div className="login-form-container">
            <h3 className="welcome-text">Welcome Back!</h3>
            <p>Please enter your credentials to access your account</p>
            <form onSubmit={loginUser} className="login-form">
              <div className="form-input">
                <label htmlFor="">Username</label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Enter Username"
                />
              </div>
              <div className="form-input">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                />
              </div>
              {/* <div className="form-bottom">
                <div className="remember-me">
                  <input type="checkbox" name="remember_me" id="remember_me" />
                  Remember Me
                </div>
                <Link>Forgot Password?</Link>
              </div> */}
              {loggingIn ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#030166"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <input type="submit" value="Login" />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
