import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";
import axios from "axios";

const EditIncomeModal = ({ incomeTypes, modal, setModal, getIncomeList, incomeDetails }) => {
  const [data, setData] = useState(incomeDetails);
  const [updatingIncome, setUpdatingIncome] = useState(false);

  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

//   UPDATE INCOME
const updateIncome = async (e) => {
    e.preventDefault()
    if(localStorage.getItem("access")){
        setUpdatingIncome(true)
        const config = {
            headers:{
                "Content-Type": "application/json",
                Authorization:"Bearer " + localStorage.getItem("access"),
            }
        }
        const body = JSON.stringify(data)
        
        try {
            await axios.put(`${api_url}/finance/income/${data?.id}/`,body, config).then((response) => {
                setModal(!modal)
                setUpdatingIncome(false)
                
                Swal.fire({
                    title:"Income Updated",
                    text:"Income has been updated successfully",
                    icon:"success"
                }).then(()=>{
                    window.location.reload()
                })
    
            })
            
        } catch (error) {
            setUpdatingIncome(false)
            console.log(error)
            toast.error("An error occurred.")    
        }
    }
}

  return (
    <div className="new-modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="new-modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Income</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => updateIncome(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Date</label>
              <input
                type="date"
                onChange={(e) => handleChange(e)}
                name="date_received"
                id="date_received"
                value={data?.date_received}
                required
              />
            </div>
            <div className="modal-form-field">
              <label>Income Type</label>
              <select
                required
                name="income_type"
                id="income_type"
                onChange={(e) => handleChange(e)}
              >
                
                {
                  incomeTypes?.map((incomeType)=>(
                    <option selected={incomeType.id === data?.income_type?.id} value={incomeType.id} key={incomeType.id}>
                      {incomeType.name}
                    </option>
                  ))
                }
              </select>
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Amount Received</label>
              <input
                type="number"
                name="amount_received"
                id="amount_received"
                placeholder="Amount Received"
                onChange={(e) => handleChange(e)}
                required
                value={data?.amount_received}
              />
            </div>
            
            <div className="modal-form-field">
              <label htmlFor="">Payer Name</label>
              <input
                type="text"
                name="payer_name"
                id="payer_name"
                placeholder="Payer Name"
                onChange={(e) => handleChange(e)}
                required
                value={data?.payer_name}
              />
            </div>
            <div className="modal-form-field">
              <label>Payment Method</label>
              <select
                required
                name="payment_method"
                id="payment_method"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Payment Method</option>
                <option
                  selected={data?.payment_method === "Cash"}
                  value="Cash"
                  onChange={(e) => handleChange(e)}
                >
                  Cash
                </option>
                <option
                  selected={data?.payment_method === "Mobile Money"}
                  value="Mobile Money"
                  onChange={(e) => handleChange(e)}
                >
                  Mobile Money
                </option>
                <option
                  selected={data?.payment_method === "Bank Transfer"}
                  value="Bank Transfer"
                  onChange={(e) => handleChange(e)}
                >
                  Bank Transfer
                </option>
              </select>
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Description</label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="5"
                required
                onChange={(e) => handleChange(e)}
                value={data?.description}
              ></textarea>
            </div>
            {updatingIncome ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#030166"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <input type="submit" value="Update Income" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditIncomeModal;
