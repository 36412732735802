import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineUserAdd,
} from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";
import AuthContext from "../../context/AuthContext";
import { RotatingLines } from "react-loader-spinner";

const VisitorsList = () => {
  const { user } = useContext(AuthContext);
  const [visitors, setVisitors] = useState([]);
  const [visitorsList, setVisitorsList] = useState([]);
  const [loadingVisitors, setLoadingVisitors] = useState(false);
  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = visitors.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(visitors.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % visitors.length;
    setItemOffset(newOffset);
  };

  const filterVisitorsList = (search) => {
    if (search === "") {
      console.log("empty");
      setVisitors(visitorsList);
    } else {
      const filteredMembersList = visitorsList.filter((member) => {
        return search.toLowerCase() === ""
          ? member
          : member.first_name.toLowerCase().includes(search.toLowerCase()) ||
              member.last_name.toLowerCase().includes(search.toLowerCase());
      });
      setVisitors(filteredMembersList);
    }
  };

  // INITIALIZE DELETE MEMBER
  const initializeDeleteVisitor = (member_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once you delete this member, you cannot revert it.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVisitor(member_id);
      }
    });
  };

  // DELETE MEMBER
  const deleteVisitor = async (member_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .delete(`${api_url}/members/${member_id}/`, config)
          .then((response) => {
            toast.success("Member deleted successfully");
            window.location.reload();
          });
      } catch (error) {
        toast.error("Error while deleting	member, please try again later");
      }
    }
  };

  useEffect(() => {
    // GET VISITORS
    const getVisitors = async () => {
      if (localStorage.getItem("access")) {
        setLoadingVisitors(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access"),
          },
        };
        try {
          await axios
            .get(`${api_url}/members/visitors/list`, config)
            .then((response) => {
              setVisitorsList(response.data);
              setVisitors(response.data);
              setLoadingVisitors(false);
            });
        } catch (error) {
          toast.error("Something went wrong.");
          setLoadingVisitors(false);
        }
      }
    };
    getVisitors();
  }, []);

  return (
    <div className="table-container">
      <div className="table-body">
        <div className="table-top">
          <div className="table--header">
            <div className="input--group">
              <input
                type="text"
                placeholder="Search Member By Name"
                name=""
                id=""
                className="form--control"
                defaultValue=""
                onChange={(e) => filterVisitorsList(e.target.value)}
              />
            </div>
            {user?.role === "Administrator" && (
              <Link to="/add-visitor" className="default-button">
                <AiOutlineUserAdd /> Add New Visitor
              </Link>
            )}
            {user?.role === "Administrative Assistant" && (
              <Link to="/add-visitor" className="default-button">
                <AiOutlineUserAdd /> Add New Visitor
              </Link>
            )}
          </div>
        </div>
        <div className="default-table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Date registered</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((visitor) => (
                <tr key={visitor.id}>
                  <td>
                    <p>{visitor.first_name}</p>
                  </td>
                  <td>{visitor.last_name}</td>
                  <td>{visitor.registration_date}</td>
                  <td className="actions">
                    <Link
                      style={{ color: "inherit" }}
                      to={`/member-profile/${visitor.id}/`}
                      className="action-btn"
                    >
                      <AiOutlineEye />
                    </Link>
                    <Link className="danger-btn ">
                      <AiOutlineDelete
                        onClick={() => initializeDeleteVisitor(visitor.id)}
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="pagination-page"
            activeClassName="pagination-active-page"
            previousClassName="pagination-previous-page"
            nextClassName="pagination-next-page"
            disabledLinkClassName="disabled-link"
          />
        </div>
      </div>
      {loadingVisitors && (
        <div className="no-data">
          <RotatingLines
            visible={true}
            height="46"
            width="46"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
          Loading data...
        </div>
      )}
      {visitors.length <= 0 && !loadingVisitors && (
        <div className="no-data">
          <img
            src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?size=626&ext=jpg&ga=GA1.1.455008150.1699805477&semt=ais"
            alt=""
          />
          No data found
        </div>
      )}
    </div>
  );
};

export default VisitorsList;
