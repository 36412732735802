import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import { MdDashboard } from "react-icons/md";
import { BiLogOut, BiSolidUser } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { PiChartLineUpBold } from "react-icons/pi";
import { TbWorldWww, TbReport } from "react-icons/tb";
import { FaClipboardList } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import AuthContext from "../../context/AuthContext";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Sidebar = () => {
  const { user, logoutUser, openSidebar, setOpenSidebar } =
    useContext(AuthContext);
  console.log(user);
  const navigations = [
    {
      icon: <MdDashboard />,
      name: "Dashboard",
      path: "/dashboard",
    },
    {
      icon: <BiSolidUser />,
      name: "User Management",
      path: "/user-management",
    },
    {
      icon: <FiUsers />,
      name: "Member Management",
      path: "/member-management",
    },
    // {
    //   "icon": <FaClipboardList />,
    //   "name":"Attendance",
    //   "path":"/attendance",
    // },
    {
      icon: <PiChartLineUpBold />,
      name: "Finance",
      path: "/finance",
    },
    // {
    //   "icon": <TbWorldWww />,
    //   "name":"Website Resource",
    //   "path":"/website-resource",
    // },
    // {
    //   "icon": <TbReport />,
    //   "name":"Report & Analytics",
    //   "path":"/report-analytics",
    // },
    {
      icon: <FaGear />,
      name: "Settings",
      path: "/settings",
    },
  ];

  const financeManagerNavigations = [
    {
      icon: <MdDashboard />,
      name: "Dashboard",
      path: "/dashboard",
    },
    {
      icon: <FiUsers />,
      name: "Member Management",
      path: "/member-management",
    },
    {
      icon: <PiChartLineUpBold />,
      name: "Finance",
      path: "/finance",
    },
    {
      icon: <FaGear />,
      name: "Settings",
      path: "/settings",
    },
  ];

  return (
    <div id="sidebar" style={{ display: `${openSidebar ? "block" : "none"}` }}>
      {openSidebar && (
        <div className="close-icon">
          <IoIosCloseCircleOutline
            onClick={() => {
              setOpenSidebar(!openSidebar);
            }}
          />
        </div>
      )}
      <div className="sidebar-title">ADMIN PORTAL</div>
      <div className="navigations">
        {user?.role === "Administrator" && (
          <>
            {navigations.map((navigation, index) => (
              <div key={index} className="navigation-item">
                <NavLink
                  to={navigation.path}
                  className="navigation-link"
                  activeclassname="active-navigation-link"
                >
                  {navigation.icon} <span>{navigation.name}</span>
                </NavLink>
              </div>
            ))}
          </>
        )}

        {user?.role === "Administrative Assistant" && (
          <>
            {navigations.map((navigation, index) => (
              <div key={index} className="navigation-item">
                <NavLink
                  to={navigation.path}
                  className="navigation-link"
                  activeclassname="active-navigation-link"
                >
                  {navigation.icon} <span>{navigation.name}</span>
                </NavLink>
              </div>
            ))}
          </>
        )}

        {user?.role === "Finance Manager" && (
          <>
            {financeManagerNavigations.map((navigation, index) => (
              <div key={index} className="navigation-item">
                <NavLink
                  to={navigation.path}
                  className="navigation-link"
                  activeclassname="active-navigation-link"
                >
                  {navigation.icon} <span>{navigation.name}</span>
                </NavLink>
              </div>
            ))}
          </>
        )}

        {user?.role === "Welfare Manager" && (
          <>
            {financeManagerNavigations.map((navigation, index) => (
              <div key={index} className="navigation-item">
                <NavLink
                  to={navigation.path}
                  className="navigation-link"
                  activeclassname="active-navigation-link"
                >
                  {navigation.icon} <span>{navigation.name}</span>
                </NavLink>
              </div>
            ))}
          </>
        )}

        <div className="navigation-item">
          <div className="navigation-link" onClick={logoutUser}>
            <BiLogOut /> Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
