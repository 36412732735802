import React from "react";
import "./Footer.scss";
import {
  BsFacebook,
  BsFillTelephoneFill,
  BsInstagram,
  BsYoutube,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-section contact-details">
        <div className="contact-detail">
          <FaLocationDot />
          <div className="info">
            <p>Opposite Christian Service University College,</p>
            <p>Odeneho Kwadaso Road</p>
            <p>Digital Address: GL-020-5834</p>
          </div>
        </div>
        <div className="contact-detail">
          <BsFillTelephoneFill />
          <div className="info">
            <p>Telephone: (+233) 571 124 180 | 0570 947 621 </p>
            <p>Helpline: (+233) 543 308 187</p>
          </div>
        </div>
        <div className="contact-detail">
          <MdEmail />
          <div className="info">
            <p>info@royalec.org</p>
          </div>
        </div>
      </div>
      <div className="footer-section social-media-mail">
        <div className="social-media-section">
          <h4>Follow Us On Social Media</h4>
          <div className="social-media">
            <div className="social-medium">
              <BsFacebook />
            </div>
            <div className="social-medium">
              <BsInstagram />
            </div>
            <div className="social-medium">
              <FaTiktok />
            </div>
            <div className="social-medium">
              <BsYoutube />
            </div>
          </div>
        </div>
        <div className="send-mail-section">
          <h4>Write to us</h4>
          <form action="">
            <div className="form-row">
              <div className="form-col">
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Your full name here"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Your email here"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Your phone number here"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <input type="submit" value="Send Message" />
          </form>
        </div>
      </div>
      <div className="footer-section">
        <h4>Locate us on map</h4>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.8043011857767!2d-1.6488678259163503!3d6.671154793323913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdb972ff00f1107%3A0xe60a0c6984b472c3!2sRoyal%20Evangelical%20Church!5e0!3m2!1sen!2sgh!4v1698140897219!5m2!1sen!2sgh"
          width="100%"
          height="300"
          style={{
            border: 0,
            allowFullScreen: "",
            loading: "lazy",
            referrerpolicy: "no-referrer-when-downgrade",
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default Footer;
