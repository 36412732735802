import axios from "axios";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ThreeDots } from 'react-loader-spinner';
import { toast } from "react-toastify";
import { api_url } from "../../../data";

const AddSermonModal = ({ modal, setModal, getSermonList }) => {
  const [data, setData] = useState({});
  const [addingSermon, setAddingSermon] = useState(false);
  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // ADD SERMON
  const addSermon = async (e) => {
    e.preventDefault()
    if(localStorage.getItem("access")){
      setAddingSermon(true)
      const config = {
        headers:{
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("access")
        }
      }
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      try {
        await axios.post(`${api_url}/resource/sermon/`, formData, config).then((response)=>{
          getSermonList()
          setModal(!modal)
          toast.success("Sermon has been added")
          setAddingSermon(false)
        })
      } catch (error) {
        setAddingSermon(false)
        console.log(error)
      }
    }
  }
  return (
    <div className="modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Add New Sermon</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={e=>addSermon(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Thumbnail</label>
              <input
                type="file"
                onChange={(e) => setData({...data, [e.target.name]:e.target.files[0]})}
                name="thumbnail"
                id="thumbnail"
                required
              />
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Date</label>
              <input
                type="date"
                onChange={(e) => handleChange(e)}
                name="date"
                id="date"
                required
              />
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Title</label>
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                name="title"
                id="title"
                required
              />
            </div>

            <div className="modal-form-field">
              <label htmlFor="">Preacher</label>
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                name="preacher"
                id="preacher"
                required
              />
            </div>

            <div className="modal-form-field">
              <label htmlFor="">Sermon Description</label>
              <textarea
                name="sermon_description"
                id="sermon_description"
                cols="30"
                rows="5"
                required
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>
            {addingSermon ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#030166"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <input type="submit" value="Add Sermon" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSermonModal;
