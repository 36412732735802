import React, { useContext, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import {
  AiOutlineUserAdd,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import Swal from "sweetalert2";
import "./UserManagement.scss";
import AddUserModal from "../../components/Modals/AddUserModal/AddUserModal";
import axios from "axios";
import { api_url } from "../../data";
import { toast } from "react-toastify";
import { useEffect } from "react";
import EditUserModal from "../../components/Modals/EditUserModal/EditUserModal";
import { RotatingLines } from "react-loader-spinner";
import AuthContext from "../../context/AuthContext";

const UserManagement = () => {
  const {user} = useContext(AuthContext)
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(true);

  // GET REGISTERED USERS
  const getRegisteredUsers = async () => {
    if (localStorage.getItem("access")) {
      setLoadingUsers(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .get(`${api_url}/accounts/user/`, config)
          .then((response) => {
            setLoadingUsers(false)
            setRegisteredUsers(response.data);
          });
      } catch (error) {
        toast.error("Error");
        console.log(error);
        setLoadingUsers(false)
      }
    }
  };

  // GET REGISTERED MEMBERS
  const getRegisteredMembers = async () => {
    if(localStorage.getItem("access")){
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        
        await axios.get(`${api_url}/members/`,config).then((response)=>{
          setRegisteredMembers(response.data)
        })
      } catch (error) {
        console.log(error)
        toast.error("Error")
      }
    }
  }

  // EDIT USER
  const activateEditUserModal = (selectedUser) => {
    setSelectedUser(selectedUser)
    setEditModal(!editModal)
  }

  // INITIALIZE DELETE USER ACCOUNT
  const initializeDeleteUser = (user_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once you delete this account, you cannot revert it.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(user_id);
      }
    });
  };

  // DELETE USER ACCOUNT
  const deleteUser = async (user_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .delete(`${api_url}/accounts/user/${user_id}/`, config)
          .then((response) => {
            if (response.status === 204) {
              toast.success("User account deleted successfully");
              window.location.reload();
            } else {
              toast.error("Something went wrong please try again");
            }
          });
      } catch (error) {
        console.log(error);
        toast.error(
          "Error while deleting user account, please try again later"
        );
      }
    }
  };

  useEffect(() => {
    if(!user.church_details){
      Swal.fire({
        title:"Church Details Not Set",
        text:"You have not set your church details, please do so before you continue",
        icon:"info"
      }).then(()=>{
        window.location.href = "/settings"
      })
    }else{
      getRegisteredUsers();
      getRegisteredMembers();
    }
  }, []);

  return (
    <div id="main-layout">
      {modal && <AddUserModal registeredMembers={registeredMembers} modal={modal} setModal={setModal} />}
      {editModal && <EditUserModal userDetails={selectedUser} registeredMembers={registeredMembers} modal={editModal} setModal={setEditModal} />}
      <Sidebar />
      <div id="main-page">
        <Topbar title="User Management" />
        <div id="user-management">
          <div className="user-list-card">
            <div className="user-list-header">
              <h4>Users</h4>
              <div className="add-user-button" onClick={() => setModal(!modal)}>
                <AiOutlineUserAdd /> Add New User
              </div>
            </div>
            <div className="user-list-table">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "25%" }}>User</th>
                    <th>Status</th>
                    <th>Position</th>
                    <th>Phone Number</th>
                    <th style={{ width: "10%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {registeredUsers.map((user) => (
                    <tr key={user.id}>
                      <td className="user">
                        <img
                          src={user.profile_picture ? user.profile_picture : "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" }
                          alt="user"
                          className="user-img"
                        />
                        <div className="user-details">
                          <p className="full-name">{user.first_name} {user.last_name}</p>
                          <p className="username">{user.username}</p>
                        </div>
                      </td>
                      <td>
                        {
                          user.is_active ? <p className="green-badge">Active</p> : <p className="red-badge">Inactive</p>
                        }
                        
                      </td>
                      <td>{user.role}</td>
                      <td>{user.phone_number}</td>
                      <td className="actions">
                        <AiOutlineEdit onClick={()=>activateEditUserModal(user)} />
                        <AiOutlineDelete onClick={()=>initializeDeleteUser(user.id)}/>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
              {
                loadingUsers && registeredUsers.length <= 0 && (
                  <div className="no-data">
                    <RotatingLines
                      visible={true}
                      height="46"
                      width="46"
                      color="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                    Loading Data...
                  </div>
                )
              }
              {
                registeredUsers.length <= 0 && !loadingUsers && (
                  <div className="no-data">
                    <img src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?size=626&ext=jpg&ga=GA1.1.455008150.1699805477&semt=ais" alt="" />
                    No data found
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
