import React,{useState} from 'react'
import ChurchLogo from "../../assets/ChurchLogo.png"
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../data";
import { ThreeDots } from "react-loader-spinner";

const ChurchSettings = ({churchDetails}) => {
    
    const data = churchDetails
    const [updateData, setUpdateData] = useState({})
    const [submitting, setSubmitting] = useState(false)
    
    // HANDLE CHANGE
    const handleChange = (e) => {
        setUpdateData({
        ...updateData,
        [e.target.name]: e.target.value,
        });
    };

    // SUBMIT FORM
  const submitForm = async (e) => {
    e.preventDefault()
    if(localStorage.getItem("access")){
      setSubmitting(true)
      const config = {
        headers:{
          "Content-Type": "multipart/form-data",
          Authorization:"Bearer " + localStorage.getItem("access")
        }
      }
      console.log(updateData)
      const formData = new FormData()
      Object.keys(updateData).forEach(key=>{
        formData.append(key, updateData[key])
      })
      
      try {
        await axios.post(`${api_url}/accounts/church-details/`, formData, config).then((response)=>{
          if(response.status === 200){
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Church Details Updated Successfully",
            }).then(()=>{
                window.location.reload();
            });
            setSubmitting(false)
          }
        })
      } catch (error) {
        console.log(error)
        setSubmitting(false)
        toast.error("An error occurred.")
      }
    }
  };

    return (
    <div className='settings-details'>
        <h3>Church</h3>
        <p>
            Update the church details here
        </p>
        <form onSubmit={e=>submitForm(e)} className="church-form">
            <div className="profile-picture-wrapper">
                <img src={churchDetails?.church_logo ? churchDetails?.church_logo : ChurchLogo} alt="" className="church-logo" />
                <div className="picture-details">
                    <h3>Church Logo</h3>
                    <label htmlFor="image-upload" className='default-button'>
                        Upload Image
                    </label>
                    <input onChange={
                        (e)=>{
                            setUpdateData({
                                ...updateData,
                                [e.target.name]:e.target.files[0]
                            })
                        }   
                    } type="file" name="church_logo" style={{display:"none"}} id="image-upload" />
                    
                    <p className="info">
                        We support PNGs and JPEGs under 10MB
                    </p>
                </div>
            </div>
            <div className="form-field">
                <label htmlFor="">Church Name</label>
                <input type="text" required value={updateData?.church_name ? updateData?.church_name : data?.church_name} onChange={e=>handleChange(e)} name="church_name" id="" />
            </div>
            <div className="form-field">
                <label htmlFor="">Phone Number</label>
                <input type="text" required value={updateData?.phone_number ? updateData?.phone_number :data?.phone_number} onChange={e=>handleChange(e)} name="phone_number" id="" />
            </div>
            <div className="form-field">
                <label htmlFor="">Email</label>
                <input type="text" required value={updateData?.email ? updateData?.email : data?.email} onChange={e=>handleChange(e)} name="email" id="" />
            </div>
            <div className="form-field">
                <label htmlFor="">Location</label>
                <input type="text" required value={updateData?.location ? updateData?.location : data?.location} onChange={e=>handleChange(e)} name="location" id="" />
            </div>
            {
                submitting ? (
                    <div className="continue-button">
                        <ThreeDots
                        height="40"
                        width="40"
                        radius="9"
                        color="#030166"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                ) : (
                    <input type="submit" value="Save Changes" className="default-button" />
                )
            }
            
        </form>
    </div>
  )
}

export default ChurchSettings