import axios from "axios";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";

const AddExpenseModal = ({expenseTypes, modal, setModal, getExpenseList}) => {
    const [data, setData] = useState({});
    const [recordingExpense, setRecordingExpense] = useState(false)


      // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  //   RECORD EXPENSE
  const recordExpense = async (e) => {
    e.preventDefault()
    if(localStorage.getItem("access")){
        setRecordingExpense(true)
        const config = {
            headers:{
                "Content-Type": "application/json",
                Authorization:"Bearer " + localStorage.getItem("access"),
            }
        }
        const body = JSON.stringify(data)
        
        try {
            await axios.post(`${api_url}/finance/expense/`,body, config).then((response) => {
                setModal(!modal)
                setRecordingExpense(false)
                getExpenseList()
                Swal.fire({
                    title:"Expense Recorded",
                    text:"Expense has been recorded successfully",
                    icon:"success"
                }).then(()=>{
                  window.location.reload()
                })
    
            })
            
        } catch (error) {
            setRecordingExpense(false)
            toast.error("An error occurred.")    
        }
    }
  }
    return (
    <div className="modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Record Expense</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e)=>recordExpense(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Date</label>
              <input
                type="date"
                onChange={(e) => handleChange(e)}
                name="date_recorded"
                id="date_recorded"
                required
              />
            </div>
            <div className="modal-form-field">
              <label>Expense Type</label>
              <select
                required
                name="expense_type"
                id="expense_type"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Expense Type</option>
                {
                  expenseTypes?.map((expenseType)=>(
                    <option value={expenseType.id} key={expenseType.id}>
                      {expenseType.name}
                    </option>
                  ))
                }
                <option value = "Other">Other</option>
              </select>
            </div>
            {
              data?.expense_type === "Other" && (
                <div className="modal-form-field">
                  <label htmlFor="">New Expense Type</label>
                  <input
                    type="text"
                    name="new_expense_type"
                    id="new_expense_type"
                    placeholder="Enter new expense type name"
                    onChange={(e) => handleChange(e)}
                    required={data?.income_type === "Other"}
                  />
                </div>    
              )
            }
            <div className="modal-form-field">
              <label htmlFor="">Amount Spent</label>
              <input
                type="number"
                name="amount_spent"
                id="amount_spent"
                placeholder="Amount Spent"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Description</label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="5"
                required
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>
            {
                recordingExpense ? (
                    <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#030166"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ):(
                    <input type="submit" value="Record Expense" />
                )
            }
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddExpenseModal