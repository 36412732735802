import React from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'

const ThemeList = ({modal, setModal, themeList}) => {
  return (
    <div className='income-page'>
        <div className="add-income">
            <div className="default-button" onClick={()=>setModal(!modal)}>Add New Theme</div>
        </div>
        <div className="default-table">
            <table>
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Theme</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        themeList.map((themeItem, index)=>(
                            <tr key={index}>
                        <td>{themeItem.year}</td>
                        <td>{themeItem.theme}</td>
                        <td>
                            {
                                themeItem.is_current_year_theme ? (
                                    <p className="green-badge">Current</p> 
                                ):(
                                    <p className="red-badge">Not Current</p> 
                                )
                            }
                        </td>
                        <td className='actions'>
                            <AiOutlineEdit />
                            <AiOutlineDelete />
                        </td>
                    </tr>
                        ))
                    }
                    
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default ThemeList