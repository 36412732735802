import {useState} from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import Logo from "../../assets/ChurchLogo.png"
import NavItem from '../NavItem/NavItem'
import './Navbar.scss'

const Navbar = () => {
    const [mobileView, setMobileView] = useState(false)
    const NavItems = [
        {
            path:"/",
            name:"HOME"
          },
          {
            path:"/about",
            name:"ABOUT US"
          },
          {
            path:"/leadership",
            name:"LEADERSHIP"
          },
          {
            path:"/departments",
            name:"DEPARTMENTS"
          },
          {
            path:"/resources",
            name:"RESOURCES"
          },
          {
            path:"/contact-us",
            name:"CONTACT US"
          },
    ]
  return (
    <div id='navbar'>
        <div className="logo">
            <img src={Logo} alt="Royal Evangelical" />
            <div className="church-name">
                <p>ROYAL</p>
                <p>EVANGELICAL</p>
                <p>CHURCH - KSI</p>
            </div>
        </div>
        <div className="navigation">
            <ul className={`${mobileView && "active-navbar"} menu-list`}>
                {
                    NavItems.map((item, index) => (
                        <NavItem key={index} item={item} />
                    ))
                }
            </ul>
        </div>
        <div className="donate-button">
            DONATE
        </div>
        <div id="mobile" onClick={()=>setMobileView(!mobileView)}>
            {
                mobileView ? <FaTimes /> : <FaBars />
            }
        </div>
    </div>
  )
}

export default Navbar