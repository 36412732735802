import React from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'

const EventList = ({modal, setModal, eventList}) => {
  return (
    <div className="income-page">
        <div className="add-income">
            <div className="default-button" onClick={()=>setModal(!modal)}>
                Add New Event
            </div>
        </div>
        <div className="default-table">
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Event Title</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        eventList.map((eventItem, index) => (
                            <tr key={index}>
                                <td>{eventItem.date}</td>
                                <td>{eventItem.title}</td>
                                <td className='actions'>
                                    <AiOutlineEdit />
                                    <AiOutlineDelete />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default EventList