import { useContext } from "react"
import { Navigate } from "react-router-dom"
import AuthContext from "../context/AuthContext"


const PrivateRoutes = ({component: Component, ...rest}) => {
    const {user} = useContext(AuthContext)

    console.log(user)
    if(!user || user === "undefined" || user === undefined || user === null ){
        <Navigate to="/" />
    }else{
        return <Component {...rest} />
    }
}

export default PrivateRoutes