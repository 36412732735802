import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import UserImage from "../../assets/userImage.png";
import { BsFillCloudUploadFill } from "react-icons/bs";
import axios from "axios";
import { api_url } from "../../data";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const AddVisitor = () => {
  const [data, setData] = useState({});
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // SUBMIT FORM
  const submitForm = async () => {
    
    if (localStorage.getItem("access")) {
      setSubmitting(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      try {
        await axios
          .post(`${api_url}/members/visitors/list/`, formData, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Visitor has been created successfully",
            }).then(()=>{
                window.location.href="/member-management"
            });
          });
      } catch (error) {
        setSubmitting(false);
        toast.error("An error occurred.");
      }
    }
  };

  return (
    <div id="main-layout">
      <Sidebar />
      <div id="main-page">
        <Topbar title="Member Management" />
        <div id="add-member">
          <div className="add-member-title">ADD NEW VISITOR</div>

          <div className="add-member-form">
            {/* PERSONAL INFORMATION */}
            <div className="member-form-section">
              <div className="member-form-section-header">
                PERSONAL INFORMATION
              </div>
              <div className="personal-info-section member-form-body">
                <div className="personal-info-details member-form-details">
                  {/* FIRST ROW */}
                  <div className="member-form-row">
                    {/* FIRST NAME */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        First Name
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        value={data?.first_name}
                        type="text"
                        onChange={(e) => handleChange(e)}
                        name="first_name"
                        id="first_name"
                        className="form--control"
                      />
                    </div>
                    {/* LAST NAME */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Last Name
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={data?.last_name}
                        onChange={(e) => handleChange(e)}
                        name="last_name"
                        id="last_name"
                      />
                    </div>
                    {/* GENDER */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Gender
                        <span class="text-danger">*</span>
                      </label>
                      <div className="radio-row">
                        <div className="radio-item">
                          <input
                            type="radio"
                            onChange={(e) => handleChange(e)}
                            name="gender"
                            value="Male"
                            defaultChecked={data?.gender === "Male"}
                          />{" "}
                          Male
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            onChange={(e) => handleChange(e)}
                            name="gender"
                            value="Female"
                            defaultChecked={data?.gender === "Female"}
                          />{" "}
                          Female
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* SECOND ROW */}
                  <div className="member-form-row">
                    {/* ADDRESS */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Address
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        name="address"
                        id="address"
                        value={data?.address}
                      />
                    </div>
                    {/* Phone Number */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Phone Number
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        onChange={(e) => handleChange(e)}
                        value={data?.phone_number}
                      />
                    </div>
                    {/* EMAIL */}
                    <div className="member-form-column">
                      <label htmlFor="">Email</label>
                      <input
                        type="email"
                        onChange={(e) => handleChange(e)}
                        name="email"
                        id="email"
                        value={data?.email}
                      />
                    </div>
                  </div>
                  {/* THIRD ROW */}
                  <div className="member-form-row">
                    {/* DATE */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Date of birth
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        name="date_of_birth"
                        id="date_of_birth"
                        value={data?.date_of_birth}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {/* HOMETOWN */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Hometown<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        name="hometown"
                        id="hometown"
                        value={data?.hometown}
                      />
                    </div>
                    {/* NATIONALITY */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Nationality<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="nationality"
                        id="nationality"
                        value={data?.nationality}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  {/* FOURTH ROW */}
                  <div className="member-form-row">
                    {/* RESIDENCE */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Residence<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        name="residence"
                        id="residence"
                        value={data?.residence}
                      />
                    </div>
                    {/* LANGUAGE SPOKEN */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Language Spoken<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="language_spoken"
                        id="language_spoken"
                        onChange={(e) => handleChange(e)}
                        value={data?.language_spoken}
                      />
                    </div>
                    {/* EMPTY SPACE */}
                    <div
                      style={{ visibility: "hidden" }}
                      className="member-form-column"
                    >
                      <label htmlFor=""></label>
                      <input type="text" />
                    </div>
                  </div>
                  {/* FIFTH ROW */}
                  <div className="member-form-row">
                    {/* MARITAL STATUS */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Marital Status
                        <span class="text-danger">*</span>
                      </label>
                      <div className="radio-row">
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="marital_status"
                            value="Single"
                            onChange={(e) => handleChange(e)}
                            defaultChecked={data?.marital_status === "Single"}
                          />{" "}
                          Single
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="marital_status"
                            value="Married"
                            onChange={(e) => handleChange(e)}
                            defaultChecked={data?.marital_status === "Married"}
                          />{" "}
                          Married
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="marital_status"
                            value="Divorced"
                            onChange={(e) => handleChange(e)}
                            defaultChecked={data?.marital_status === "Divorced"}
                          />{" "}
                          Divorced
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="marital_status"
                            value="Remarried"
                            onChange={(e) => handleChange(e)}
                            defaultChecked={
                              data?.marital_status === "Remarried"
                            }
                          />{" "}
                          Remarried
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="marital_status"
                            value="Co-Habitation"
                            onChange={(e) => handleChange(e)}
                            defaultChecked={
                              data?.marital_status === "Co-Habitation"
                            }
                          />{" "}
                          Co - Habitation
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="personal-info-image">
                  <div className="image-container">
                    <img
                      src={file ? URL.createObjectURL(file) : UserImage}
                      alt="user"
                    />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_picture"
                    id="profile_picture"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      setData({
                        ...data,
                        [e.target.name]: e.target.files[0],
                      });
                    }}
                  />
                  <label id="upload-button" htmlFor="profile_picture">
                    <BsFillCloudUploadFill />
                    Upload File
                  </label>
                  <p style={{ fontWeight: "400", fontSize: "12px" }}>
                    {file ? file.name : "No file selected"}
                  </p>
                </div>
              </div>
            </div>

            {/* SPIRITUAL LIFE INFORMATION */}
            <div className="member-form-section">
              <div className="member-form-section-header">
                SPIRITUAL LIFE INFORMATION
              </div>
              <div className="member-form-body">
                <div className="member-form-details">
                  {/* FIRST ROW */}

                  {/* THIRD ROW */}
                  <div className="member-form-row">
                    {/* AREA OF MINISTRY */}
                    <div className="member-form-column">
                      <label htmlFor="">Area of Ministry/Gifting</label>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        name="ministry"
                        id="ministry"
                        value={data?.ministry}
                      />
                    </div>
                    {/* ARE YOU BORN AGAIN */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Are You Born Again?<span class="text-danger">*</span>
                      </label>
                      <div className="radio-row">
                        <div className="radio-item">
                          <input
                            onChange={(e) => handleChange(e)}
                            type="radio"
                            name="born_again"
                            value="Yes"
                            defaultChecked={data?.born_again === "Yes"}
                          />{" "}
                          Yes
                        </div>
                        <div className="radio-item">
                          <input
                            onChange={(e) => handleChange(e)}
                            type="radio"
                            name="born_again"
                            value="No"
                            defaultChecked={data?.born_again === "No"}
                          />{" "}
                          No
                        </div>
                      </div>
                    </div>
                    {/* HOLY SPIRIT BAPTIZED */}
                    <div className="member-form-column">
                      <label htmlFor="">
                        Holy Spirit Baptized?<span class="text-danger">*</span>
                      </label>
                      <div className="radio-row">
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="holy_spirit_baptized"
                            value="Yes"
                            onChange={(e) => handleChange(e)}
                            defaultChecked={
                              data?.holy_spirit_baptized === "Yes"
                            }
                          />{" "}
                          Yes
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="holy_spirit_baptized"
                            value="No"
                            onChange={(e) => handleChange(e)}
                            defaultChecked={data?.holy_spirit_baptized === "No"}
                          />{" "}
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {submitting ? (
              <div className="continue-button">
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#030166"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="continue-button">
                <div
                  className="default-button"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Submit
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVisitor;
