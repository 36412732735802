import React, {useState} from "react";
import "./Attendance.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";

const Attendance = () => {
  const [dataFiltered, setDataFiltered] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const filterData = (e) => {
    e.preventDefault();
    setDataFiltered(true);
  };
  return (
    <div id="main-layout">
      <Sidebar />
      <div id="main-page">
        <Topbar title="Attendance" />
        <div id="attendance">
          <div className="attendance-top">
            <div className="attendance-title">Attendance Records</div>
            <div className="default-button">Record Attendance</div>
          </div>
          <div className="filter-box">
            <p>Filter by date:</p>
            <form onSubmit={(e)=>filterData(e)}>
              <input type="date" />
              <input type="submit" value="Filter" />
            </form>
          </div>
          {
            dataFiltered && (
              <div className="attendance-body">
                <div className="tabs">
                  <div className={`${activeTab === 1 ? "tab tab-active" : "tab"}`} onClick={()=>setActiveTab(1)} >
                    Present
                  </div>
                  <div className={`${activeTab === 2 ? "tab tab-active" : "tab"}`} onClick={()=>setActiveTab(2)} >
                    Absent
                  </div>
                </div>
                {/* PRESENT TABLE */}
                {
                  activeTab === 1 && (
                    <div className="default-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Member Name</th>
                            <th>Time</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="member">
                              <img
                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                                alt="user"
                                className="user-img"
                              />
                              <p>Michael Okyere Adams</p>
                            </td>
                            <td>8:30am</td>
                            <td>20 September, 2023</td>
                            <td>
                              <div className="green-badge">
                                  Present
                              </div>
                            </td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                }
                {/* ABSENT TABLE */}
                {
                  activeTab === 2 && (
                    <div className="default-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Member Name</th>
                            <th>Time</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="member">
                              <img
                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                                alt="user"
                                className="user-img"
                              />
                              <p>Michael Okyere Adams</p>
                            </td>
                            <td>8:30am</td>
                            <td>20 September, 2023</td>
                            <td>
                              <div className="red-badge">
                                  Absent
                              </div>
                            </td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Attendance;
