import React, { useContext, useState } from "react";
import "./MemberManagement.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import {
  AiOutlineUserAdd,
  AiOutlineEye,
  AiOutlineDelete,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { api_url } from "../../data";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import VisitorsList from "./VisitorsList";
import { RotatingLines } from "react-loader-spinner";
import AuthContext from "../../context/AuthContext";

const MemberManagement = () => {
  const {user} = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState(1);
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const [initialMembersList, setInitialMembersList] = useState([]);
  const [visitorsList, setVisitorsList] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [loadingMembers, setLoadingMembers] = useState(true)

  const filterMembersList = (search) => {
    if (search === "") {
      console.log("empty");
      setRegisteredMembers(initialMembersList);
    }else{
      const filteredMembersList = registeredMembers.filter((member) => {
        return search.toLowerCase() === ""
          ? member
          : member.first_name.toLowerCase().includes(search.toLowerCase()) ||
              member.last_name.toLowerCase().includes(search.toLowerCase());
      });
      setRegisteredMembers(filteredMembersList);
    }
  };

  // GET REGISTERED MEMBERS
  const getRegisteredMembers = async () => {
    if (localStorage.getItem("access")) {
      setLoadingMembers(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios.get(`${api_url}/members/`, config).then((response) => {
          setInitialMembersList(response.data);
          setRegisteredMembers(response.data);
          setLoadingMembers(false)
        });
      } catch (error) {
        toast.error("Something went wrong.");
        setLoadingMembers(false)
      }
    }
  };

  // GET VISITORS
  // GET REGISTERED MEMBERS
  const getVisitors = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios.get(`${api_url}/members/visitors/list`, config).then((response) => {
          setVisitorsList(response.data);
        });
      } catch (error) {
        toast.error("Something went wrong.");
      }
    }
  };

  // INITIALIZE DELETE MEMBER
  const initializeDeleteMember = (member_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once you delete this member, you cannot revert it.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMember(member_id);
      }
    });
  };

  // DELETE MEMBER
  const deleteMember = async (member_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .delete(`${api_url}/members/${member_id}/`, config)
          .then((response) => {
            toast.success("Member deleted successfully");
            getRegisteredMembers();
          });
      } catch (error) {
        console.log(error);
        toast.error("Error while deleting	member, please try again later");
      }
    }
  };

  const itemsPerPage = 20;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = registeredMembers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(registeredMembers.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % registeredMembers.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if(!user.church_details){
      Swal.fire({
        title:"Church Details Not Set",
        text:"You have not set your church details, please do so before you continue",
        icon:"info"
      }).then(()=>{
        window.location.href = "/settings"
      })
    }else{
      getRegisteredMembers();
      getVisitors()
    }
  }, []);
  return (
    <div id="main-layout">
      <Sidebar />
      <div id="main-page">
        <Topbar title="Member Management" />
        <div id="member-management">
          <div className="member-management-top">
            <div className="member-management-tabs">
              <div
                onClick={() => setActiveTab(1)}
                className={`${
                  activeTab === 1
                    ? "member-management-tab active-tab"
                    : "member-management-tab"
                }`}
              >
                Members List
              </div>
              <div
                onClick={() => setActiveTab(2)}
                className={`${
                  activeTab === 2
                    ? "member-management-tab active-tab"
                    : "member-management-tab"
                }`}
              >
                Visitors List
              </div>
            </div>
          </div>
          {activeTab === 1 && (
            <div className="table-container">
              <div className="table-body">
                <div className="table-top">
                  <div className="table--header">
                    <div className="input--group">
                      <input
                        type="text"
                        placeholder="Search Member By Name"
                        name=""
                        id=""
                        defaultValue=""
                        onChange={(e) => filterMembersList(e.target.value)}
                        className="form--control"
                      />
                    </div>
                    {
                      user?.role === "Administrator" && (
                        <Link to="/add-member" className="default-button">
                          <AiOutlineUserAdd /> Add New Member
                        </Link>
                      )
                    }
                    {
                      user?.role === "Administrative Assistant" && (
                        <Link to="/add-member" className="default-button">
                          <AiOutlineUserAdd /> Add New Member
                        </Link>
                      )
                    }
                    
                  </div>
                </div>
                <div className="default-table">
                  <table>
                    <thead>
                      <tr>
                        <th>SRL</th>
                        <th>Member ID</th>
                        <th>Full Name</th>
                        <th>Position</th>
                        <th>Department</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((member, index) => (
                        <tr key={member.id}>
                          <td>{index + 1}</td>
                          <td>{member.member_church_id}</td>
                          <td>
                            <p>
                              {member.first_name} {member.last_name}
                            </p>
                          </td>
                          <td>{member.position}</td>
                          <td>{member.department}</td>
                          <td className="actions">
                            <Link
                              style={{ color: "inherit" }}
                              to={`/member-profile/${member.id}/`}
                              className="action-btn"
                            >
                              <AiOutlineEye />
                            </Link>
                            <Link className="danger-btn ">
                              <AiOutlineDelete
                                onClick={() =>
                                  initializeDeleteMember(member.id)
                                }
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="›"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="‹"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageClassName="pagination-page"
                    activeClassName="pagination-active-page"
                    previousClassName="pagination-previous-page"
                    nextClassName="pagination-next-page"
                    disabledLinkClassName="disabled-link"
                  />
                </div>
              </div>
              {
                loadingMembers && (
                  <div className="no-data">
                    <RotatingLines
                      visible={true}
                      height="46"
                      width="46"
                      color="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                    Loading data...
                  </div>  
                )
              }
              {registeredMembers.length <= 0 && !loadingMembers && (
                <div className="no-data">
                  <img
                    src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?size=626&ext=jpg&ga=GA1.1.455008150.1699805477&semt=ais"
                    alt=""
                  />
                  No data found
                </div>
              )}
            </div>
          )}

          {activeTab === 2 && (
            <VisitorsList visitorsList={visitorsList} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberManagement;
