import axios from "axios";
import React, {useState} from "react";
import { FaTimes } from "react-icons/fa";
import { ThreeDots } from 'react-loader-spinner';
import { toast } from "react-toastify";
import { api_url } from "../../../data";

const AddEventModal = ({ modal, setModal, getEventList }) => {
  const [data, setData] = useState({});
  const [addingEvent, setAddingEvent] = useState(false);

  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const addEvent = async (e) => {
    e.preventDefault();
    if(localStorage.getItem("access")){
      setAddingEvent(true)
      const config = {
        headers:{
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("access")
        }
      }
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      try {
        await axios.post(`${api_url}/resource/event/`, formData, config).then((response)=>{
          getEventList()
          setModal(!modal)
          toast.success("Event has been added")
          setAddingEvent(false)
        })
      } catch (error) {
        setAddingEvent(false)
        console.log(error)
      }
    }
  }
  return (
    <div className="modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Add New Event</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => addEvent(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Thumbnail *</label>
              <input
                type="file"
                onChange={(e) => setData({...data, [e.target.name]:e.target.files[0]})}
                name="thumbnail"
                id="thumbnail"
                required
              />
            </div>

            <div className="modal-form-field">
              <label htmlFor="">Title*</label>
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                name="title"
                id="title"
                required
              />
            </div>

            <div className="modal-form-field">
              <label htmlFor="">Description</label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="5"
                required
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>

            <div className="modal-form-field">
              <label htmlFor="">Date*</label>
              <input
                type="date"
                onChange={(e) => handleChange(e)}
                name="date"
                id="date"
                required
              />
            </div>

            <div className="modal-form-field">
              <label htmlFor="">Time*</label>
              <input
                type="time"
                onChange={(e) => handleChange(e)}
                name="time"
                id="time"
                required
              />
            </div>

            

            
            
            {addingEvent ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#030166"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <input type="submit" value="Add Event" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEventModal;
