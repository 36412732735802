import React, {useState} from "react";
import { Route, Routes } from "react-router-dom";
import AddMember from "../pages/AddMember/AddMember";
import Attendance from "../pages/Attendance/Attendance";
import Dashboard from "../pages/Dashboard/Dashboard";
import Finance from "../pages/Finance/Finance";
import MemberManagement from "../pages/Membermanagement/MemberManagement";
import MemberProfile from "../pages/MemberProfile/MemberProfile";
import Settings from "../pages/Settings/Settings";
import UserManagement from "../pages/Usermanagement/UserManagement";
import WebsiteResource from "../pages/WebsiteResource/WebsiteResource";
import AddVisitor from "../pages/AddMember/AddVisitor";

const Layout = () => {
  const [openSidebar, setOpenSidebar] = useState(false)
  return (
    
    <Routes>
      <Route path="/dashboard" element={<Dashboard openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route path="/member-management" element={<MemberManagement />} />
      <Route path="/member-profile/:member_id/" element={<MemberProfile />} />
      <Route path="/add-member" element={<AddMember />} />
      <Route path="/add-visitor" element={<AddVisitor />} />
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/finance" element={<Finance />} />
      <Route path="/website-resource" element={<WebsiteResource />} />
      <Route path="/settings" element={<Settings />} />
    </Routes>
  );
};

export default Layout;
