import React, {useState} from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import EditExpenseModal from "./EditExpenseModal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";
import axios from "axios";

const ExpenseList = ({expenseTypes, modal, setModal, expenseList}) => {
  const [selectedExpense, setSelectedExpense] = useState(null)
  const [editExpenseModal, setEditExpenseModal] = useState(false)
  

  const editExpense = (expense)=>{
    setSelectedExpense(expense)
    setEditExpenseModal(!editExpenseModal)
  }

  // INITIALIZE DELETE EXPENSE ACCOUNT
  const initializeDeleteExpense = (expense_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once you delete this expense, you cannot revert it.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExpense(expense_id);
      }
    });
  };

  // DELETE EXPENSE ACCOUNT
  const deleteExpense = async (expense_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .delete(`${api_url}/finance/expense/${expense_id}/`, config)
          .then((response) => {
            if (response.status === 204) {
              toast.success("Expense deleted successfully");
              window.location.reload();
            } else {
              toast.error("Something went wrong please try again");
            }
          });
      } catch (error) {
        console.log(error);
        toast.error(
          "Error while deleting expense, please try again later"
        );
      }
    }
  };


  
  return (
    <div className="income-page">
      {
          editExpenseModal && <EditExpenseModal expenseTypes = {expenseTypes} modal = {editExpenseModal} setModal = {setEditExpenseModal} expenseDetails={selectedExpense}  />
        }
      <div className="add-income">
        <div className="default-button" onClick={()=>setModal(!modal)}>Record Expense</div>
      </div>
      <div className="default-table">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Expense ID</th>
              <th>Amount</th>
              <th>Recorded By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              expenseList.map((expense, index)=>(
                <tr key={index}>
                <td>{expense.date_recorded}</td>
                <td>#{expense.expense_id}</td>
                <td>GHS {expense.amount_spent}</td>
                <td>{expense.recorded_by}</td>
                <td className="actions">
                  <AiOutlineEdit onClick={()=>editExpense(expense)} />
                  <AiOutlineDelete onClick={()=>initializeDeleteExpense(expense.id)} />
                </td>
              </tr>
              ))
            }
           
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpenseList;
