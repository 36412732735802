import axios from 'axios';
import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { api_url } from '../../../data';

const AddThemeModal = ({modal,setModal,getThemeList}) => {
  const [data, setData] = useState({});
  const [addingTheme, setAddingTheme] = useState(false);

  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // ADD THEME
  const addTheme = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setAddingTheme(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      const body = JSON.stringify(data);
      try {
        await axios
          .post(`${api_url}/resource/theme/`, body, config)
          .then((response) => {
            getThemeList();
            setModal(!modal);
            toast.success("Theme added");
            setAddingTheme(false);
          });
      } catch (error) {
        setAddingTheme(false);
        if (error.response) {
          for (const property in error.response.data) {
            toast.error(`${property} error: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };
  return (
    <div className="modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Add New Theme</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => addTheme(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Year</label>
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                name="year"
                id="year"
                required
              />
            </div>

            <div className="modal-form-field">
              <label htmlFor="">Theme</label>
              <textarea
                name="theme"
                id="theme"
                cols="30"
                rows="5"
                required
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Current year theme</label>
              <span>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setData({ ...data, [e.target.name]: e.target.checked })
                  }
                  name="is_current_year_theme"
                  id="is_current_year_theme"
                />{" "}
                Set As Current Year Theme
              </span>
            </div>
            {addingTheme ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#030166"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <input type="submit" value="Add Theme" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddThemeModal