import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url } from "../../data";
import axios from "axios";

const EditExpenseModal = ({ expenseTypes, modal, setModal,  expenseDetails }) => {
  const [data, setData] = useState(expenseDetails);
  const [updatingExpense, setUpdatingExpense] = useState(false);

  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

//   UPDATE EXPENSE
const updateExpense = async (e) => {
    e.preventDefault()
    if(localStorage.getItem("access")){
        setUpdatingExpense(true)
        const config = {
            headers:{
                "Content-Type": "application/json",
                Authorization:"Bearer " + localStorage.getItem("access"),
            }
        }
        const body = JSON.stringify(data)
        
        try {
            await axios.put(`${api_url}/finance/expense/${data?.id}/`,body, config).then((response) => {
                setModal(!modal)
                setUpdatingExpense(false)
                
                Swal.fire({
                    title:"Expense Updated",
                    text:"Expense has been updated successfully",
                    icon:"success"
                }).then(()=>{
                    window.location.reload()
                })
    
            })
            
        } catch (error) {
            setUpdatingExpense(false)
            console.log(error)
            toast.error("An error occurred.")    
        }
    }
}

  return (
    <div className="new-modal-overlay" onClick={() => setModal(!modal)}>
      <div
        className="new-modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>Expense</h3>
          <FaTimes onClick={() => setModal(!modal)} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e)=>updateExpense(e)} className="add-user-form">
            <div className="modal-form-field">
              <label htmlFor="">Date</label>
              <input
                type="date"
                onChange={(e) => handleChange(e)}
                name="date_recorded"
                id="date_recorded"
                required
                value={data?.date_recorded}
              />
            </div>
            <div className="modal-form-field">
              <label>Expense Type</label>
              <select
                required
                name="expense_type"
                id="expense_type"
                onChange={(e) => handleChange(e)}
              >
                
                {
                  expenseTypes?.map((expenseType)=>(
                    <option selected = {expenseType.id === data?.expense_type} value={expenseType.id} key={expenseType.id}>
                      {expenseType.name}
                    </option>
                  ))
                }
                
              </select>
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Amount Spent</label>
              <input
                type="number"
                name="amount_spent"
                id="amount_spent"
                placeholder="Amount Spent"
                onChange={(e) => handleChange(e)}
                required
                value={data?.amount_spent}
              />
            </div>
            <div className="modal-form-field">
              <label htmlFor="">Description</label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="5"
                required
                onChange={(e) => handleChange(e)}
              >
                {data?.description}
              </textarea>
            </div>
            {
                updatingExpense ? (
                    <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#030166"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ):(
                    <input type="submit" value="Update Expense" />
                )
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditExpenseModal;
