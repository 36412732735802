import React, { useContext, useState } from "react";
import UserImage from "../../assets/userImage.png";
import { BsFillCloudUploadFill } from "react-icons/bs";
import MemberDetails2 from "./MemberDetails2";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../data";
import Swal from "sweetalert2";
import { ThreeDots } from "react-loader-spinner";
import AddChildModal from "./AddChildModal";
import AuthContext from "../../context/AuthContext";

const MemberDetails = ({ memberDetails, memberChildren }) => {
  const {user} = useContext(AuthContext)
  const [page, setPage] = useState(1);
  const [data, setData] = useState(memberDetails);
  const [file, setFile] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [addChildModal, setAddChildModal] = useState(false)

  // HANDLE CHANGE
  const handleChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  // SUBMIT FORM
  const updateDetails = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("access")) {
      setUpdating(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };

      const formData = new FormData();
      Object.keys(updateData).forEach((key) => {
        formData.append(key, updateData[key]);
      });
      try {
        await axios
          .put(`${api_url}/members/${memberDetails?.id}/`, formData, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Member has been updated successfully",
            });
            setUpdating(false);
          });
      } catch (error) {
        setUpdating(false);
        toast.error("An error occurred.");
      }
    }
  };

  // APPROVE MEMBERSHIP
  const approveMembership = async () => {
    if (localStorage.getItem("access")) {
      setUpdating(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };

      const body = JSON.stringify({
        is_permanent_member: true,
      });
      try {
        await axios
          .put(`${api_url}/members/${memberDetails?.id}/`, body, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Member has been approved",
            }).then(() => {
              window.location.reload();
            });
            setUpdating(false);
          });
      } catch (error) {
        setUpdating(false);
        toast.error("An error occurred.");
      }
    }
  };

  return (
    <div id="add-member" style={{paddingTop:"30px"}}>
      {
        addChildModal && <AddChildModal modal = {addChildModal} setModal={setAddChildModal} member_id={memberDetails?.id} />
      }
      
      <div className="add-member-title">MEMBER DETAILS</div>
      {/* PAGE 1 */}
      {page === 1 && (
        <div className="add-member-form">
          {/* PERSONAL INFORMATION */}
          <div className="member-form-section">
            <div className="member-form-section-header">
              PERSONAL INFORMATION
            </div>
            <div className="personal-info-section member-form-body">
              <div className="personal-info-details member-form-details">
                {/* FIRST ROW */}
                <div className="member-form-row">
                  {/* FIRST NAME */}
                  <div className="member-form-column">
                    <label htmlFor="">First Name</label>
                    <input
                      value={
                        updateData?.first_name
                          ? updateData?.first_name
                          : data?.first_name
                      }
                      type="text"
                      onChange={(e) => handleChange(e)}
                      name="first_name"
                      id="first_name"
                    />
                  </div>
                  {/* LAST NAME */}
                  <div className="member-form-column">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      value={
                        updateData?.last_name
                          ? updateData?.last_name
                          : data?.last_name
                      }
                      onChange={(e) => handleChange(e)}
                      name="last_name"
                      id="last_name"
                    />
                  </div>
                  {/* GENDER */}
                  <div className="member-form-column">
                    <label htmlFor="">Gender</label>
                    <div className="radio-row">
                      <div className="radio-item">
                        <input
                          type="radio"
                          onChange={(e) => handleChange(e)}
                          name="gender"
                          value="Male"
                          defaultChecked={
                            updateData?.gender
                              ? updateData?.gender === "Male"
                              : data?.gender === "Male"
                          }
                        />{" "}
                        Male
                      </div>
                      <div className="radio-item">
                        <input
                          type="radio"
                          onChange={(e) => handleChange(e)}
                          name="gender"
                          value="Female"
                          defaultChecked={
                            updateData?.gender
                              ? updateData?.gender === "Female"
                              : data?.gender === "Female"
                          }
                        />{" "}
                        Female
                      </div>
                    </div>
                  </div>
                </div>
                {/* SECOND ROW */}
                <div className="member-form-row">
                  {/* ADDRESS */}
                  <div className="member-form-column">
                    <label htmlFor="">Address</label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e)}
                      name="address"
                      id="address"
                      value={
                        updateData?.address
                          ? updateData?.address
                          : data?.address
                      }
                    />
                  </div>
                  {/* Phone Number */}
                  <div className="member-form-column">
                    <label htmlFor="">Phone Number</label>
                    <input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      onChange={(e) => handleChange(e)}
                      value={
                        updateData?.phone_number
                          ? updateData?.phone_number
                          : data?.phone_number
                      }
                    />
                  </div>
                  {/* EMAIL */}
                  <div className="member-form-column">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      onChange={(e) => handleChange(e)}
                      name="email"
                      id="email"
                      value={
                        updateData?.email ? updateData?.email : data?.email
                      }
                    />
                  </div>
                </div>
                {/* THIRD ROW */}
                <div className="member-form-row">
                  {/* DATE */}
                  <div className="member-form-column">
                    <label htmlFor="">Date of birth</label>
                    <input
                      type="date"
                      name="date_of_birth"
                      id="date_of_birth"
                      value={
                        updateData?.date_of_birth
                          ? updateData?.date_of_birth
                          : data?.date_of_birth
                      }
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {/* HOMETOWN */}
                  <div className="member-form-column">
                    <label htmlFor="">Hometown</label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e)}
                      name="hometown"
                      id="hometown"
                      value={
                        updateData?.hometown
                          ? updateData?.hometown
                          : data?.hometown
                      }
                    />
                  </div>
                  {/* NATIONALITY */}
                  <div className="member-form-column">
                    <label htmlFor="">Nationality</label>
                    <input
                      type="text"
                      name="nationality"
                      id="nationality"
                      value={
                        updateData?.nationality
                          ? updateData?.nationality
                          : data?.nationality
                      }
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                {/* FOURTH ROW */}
                <div className="member-form-row">
                  {/* RESIDENCE */}
                  <div className="member-form-column">
                    <label htmlFor="">Residence</label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e)}
                      name="residence"
                      id="residence"
                      value={
                        updateData?.residence
                          ? updateData?.residence
                          : data?.residence
                      }
                    />
                  </div>
                  {/* LANGUAGE SPOKEN */}
                  <div className="member-form-column">
                    <label htmlFor="">Language Spoken</label>
                    <input
                      type="text"
                      name="language_spoken"
                      id="language_spoken"
                      onChange={(e) => handleChange(e)}
                      value={
                        updateData?.language_spoken
                          ? updateData?.language_spoken
                          : data?.language_spoken
                      }
                    />
                  </div>
                  {/* EMPTY SPACE */}
                  <div
                    style={{ visibility: "hidden" }}
                    className="member-form-column"
                  >
                    <label htmlFor=""></label>
                    <input type="text" />
                  </div>
                </div>
                {/* FIFTH ROW */}
                <div className="member-form-row">
                  {/* MARITAL STATUS */}
                  <div className="member-form-column">
                    <label htmlFor="">Marital Status</label>
                    <div className="radio-row">
                      <div className="radio-item">
                        <input
                          type="radio"
                          name="marital_status"
                          value="Single"
                          onChange={(e) => handleChange(e)}
                          defaultChecked={
                            updateData?.marital_status
                              ? updateData?.marital_status === "Single"
                              : data?.marital_status === "Single"
                          }
                        />{" "}
                        Single
                      </div>
                      <div className="radio-item">
                        <input
                          type="radio"
                          name="marital_status"
                          value="Married"
                          onChange={(e) => handleChange(e)}
                          defaultChecked={
                            updateData?.marital_status
                              ? updateData?.marital_status === "Married"
                              : data?.marital_status === "Married"
                          }
                        />{" "}
                        Married
                      </div>
                      <div className="radio-item">
                        <input
                          type="radio"
                          name="marital_status"
                          value="Divorced"
                          onChange={(e) => handleChange(e)}
                          defaultChecked={
                            updateData?.marital_status
                              ? updateData?.marital_status === "Divorced"
                              : data?.marital_status === "Divorced"
                          }
                        />{" "}
                        Divorced
                      </div>
                      <div className="radio-item">
                        <input
                          type="radio"
                          name="marital_status"
                          value="Remarried"
                          onChange={(e) => handleChange(e)}
                          defaultChecked={
                            updateData?.marital_status
                              ? updateData?.marital_status === "Remarried"
                              : data?.marital_status === "Remarried"
                          }
                        />{" "}
                        Remarried
                      </div>
                      <div className="radio-item">
                        <input
                          type="radio"
                          name="marital_status"
                          value="Co-Habitation"
                          onChange={(e) => handleChange(e)}
                          defaultChecked={
                            updateData?.marital_status
                              ? updateData?.marital_status === "Co-Habitation"
                              : data?.marital_status === "Co-Habitation"
                          }
                        />{" "}
                        Co - Habitation
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="personal-info-image">
                <div className="image-container">
                  <img
                    // src={file ? URL.createObjectURL(file) : UserImage}
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : memberDetails.profile_picture
                        ? memberDetails.profile_picture
                        : UserImage
                    }
                    alt="user"
                  />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  name="profile_picture"
                  id="profile_picture"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    setUpdateData({
                      ...updateData,
                      [e.target.name]: e.target.files[0],
                    });
                  }}
                />
                <label id="upload-button" htmlFor="profile_picture">
                  <BsFillCloudUploadFill />
                  Upload File
                </label>
                <p style={{ fontWeight: "400", fontSize: "12px" }}>
                  {file ? file.name : "No file selected"}
                </p>
              </div>
            </div>
          </div>

          {memberDetails?.is_permanent_member && (
            <>
              {/* SPIRITUAL LIFE INFORMATION */}
              <div className="member-form-section">
                <div className="member-form-section-header">
                  SPIRITUAL LIFE INFORMATION
                </div>
                <div className="member-form-body">
                  <div className="member-form-details">
                    {/* FIRST ROW */}
                    <div className="member-form-row">
                      {/* PREVIOUS CHURCH */}
                      <div className="member-form-column">
                        <label htmlFor="">Previous Church</label>
                        <input
                          type="text"
                          name="previous_church"
                          id="previous_church"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.previous_church
                              ? updateData?.previous_church
                              : data?.previous_church
                          }
                        />
                      </div>
                      {/* LOCATION */}
                      <div className="member-form-column">
                        <label htmlFor="">Location</label>
                        <input
                          type="text"
                          onChange={(e) => handleChange(e)}
                          name="location"
                          id="location"
                          value={
                            updateData?.location
                              ? updateData?.location
                              : data?.location
                          }
                        />
                      </div>
                      {/* PASTOR IN CHARGE */}
                      <div className="member-form-column">
                        <label htmlFor="">Pastor In Charge</label>
                        <input
                          type="text"
                          name="pastor_in_charge"
                          id="pastor_in_charge"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.pastor_in_charge
                              ? updateData?.pastor_in_charge
                              : data?.pastor_in_charge
                          }
                        />
                      </div>
                    </div>
                    {/* SECOND ROW */}
                    <div className="member-form-row">
                      {/* REASON FOR LEAVING */}
                      <div className="member-form-column">
                        <label htmlFor="">Reason For Leaving</label>
                        <input
                          type="text"
                          name="leaving_reason"
                          id="leaving_reason"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.leaving_reason
                              ? updateData?.leaving_reason
                              : data?.leaving_reason
                          }
                        />
                      </div>
                    </div>
                    {/* THIRD ROW */}
                    <div className="member-form-row">
                      {/* DATE */}
                      <div className="member-form-column">
                        <label htmlFor="">Contact Number</label>
                        <input
                          type="text"
                          name="contact_number"
                          id="contact_number"
                          value={
                            updateData?.contact_number
                              ? updateData?.contact_number
                              : data?.contact_number
                          }
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {/* TITHE CARD NUMBER */}
                      <div className="member-form-column">
                        <label htmlFor="">Tithe Card Number</label>
                        <input
                          type="text"
                          name="tithe_number"
                          id="tithe_number"
                          value={
                            updateData?.tithe_number
                              ? updateData?.tithe_number
                              : data?.tithe_number
                          }
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {/* AREA OF MINISTRY */}
                      <div className="member-form-column">
                        <label htmlFor="">Area of Ministry/Gifting</label>
                        <input
                          type="text"
                          onChange={(e) => handleChange(e)}
                          name="ministry"
                          id="ministry"
                          value={
                            updateData?.ministry
                              ? updateData?.ministry
                              : data?.ministry
                          }
                        />
                      </div>
                    </div>
                    {/* FOURTH ROW */}
                    <div className="member-form-row">
                      {/* DATE OF MEMBERSHIP CLASS COMPLETION */}
                      <div className="member-form-column">
                        <label htmlFor="">
                          Date You Completed Membership Class
                        </label>
                        <input
                          type="date"
                          name="membership_class_completion_date"
                          id="membership_class_completion_date"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.membership_class_completion_date
                              ? updateData?.membership_class_completion_date
                              : data?.membership_class_completion_date
                          }
                        />
                      </div>
                      {/* BAPTISM DATE */}
                      <div className="member-form-column">
                        <label htmlFor="">Baptismal Date</label>
                        <input
                          type="date"
                          name="baptismal_date"
                          id="baptismal_date"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.baptismal_date
                              ? updateData?.baptismal_date
                              : data?.baptismal_date
                          }
                        />
                      </div>
                      {/* BAPTISMAL TYPE */}
                      <div className="member-form-column">
                        <label htmlFor="">Baptismal Type</label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="baptismal_type"
                              value="Immersion"
                              defaultChecked={
                                updateData?.baptismal_type
                                  ? updateData?.baptismal_type === "Immersion"
                                  : data?.baptismal_type === "Immersion"
                              }
                              onChange={(e) => handleChange(e)}
                            />{" "}
                            Immersion
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="baptismal_type"
                              value="Sprinkle"
                              defaultChecked={
                                updateData?.baptismal_type
                                  ? updateData?.baptismal_type === "Sprinkle"
                                  : data?.baptismal_type === "Sprinkle"
                              }
                              onChange={(e) => handleChange(e)}
                            />{" "}
                            Sprinkle
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="baptismal_type"
                              value="Other"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={
                                updateData?.baptismal_type
                                  ? updateData?.baptismal_type === "Other"
                                  : data?.baptismal_type === "Other"
                              }
                            />{" "}
                            Other
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* FIFTH ROW */}
                    <div className="member-form-row">
                      {/* ARE YOU BORN AGAIN */}
                      <div className="member-form-column">
                        <label htmlFor="">Are You Born Again?</label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              onChange={(e) => handleChange(e)}
                              type="radio"
                              name="born_again"
                              value="Yes"
                              defaultChecked={
                                updateData?.born_again
                                  ? updateData?.born_again === "Yes"
                                  : data?.born_again === "Yes"
                              }
                            />{" "}
                            Yes
                          </div>
                          <div className="radio-item">
                            <input
                              onChange={(e) => handleChange(e)}
                              type="radio"
                              name="born_again"
                              value="No"
                              defaultChecked={
                                updateData?.born_again
                                  ? updateData?.born_again === "No"
                                  : data?.born_again === "No"
                              }
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>
                      {/* HOLY SPIRIT BAPTIZED */}
                      <div className="member-form-column">
                        <label htmlFor="">Holy Spirit Baptized?</label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="holy_spirit_baptized"
                              value="Yes"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={
                                updateData?.holy_spirit_baptized
                                  ? updateData?.holy_spirit_baptized === "Yes"
                                  : data?.holy_spirit_baptized === "Yes"
                              }
                            />{" "}
                            Yes
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="holy_spirit_baptized"
                              value="No"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={
                                updateData?.holy_spirit_baptized
                                  ? updateData?.holy_spirit_baptized === "No"
                                  : data?.holy_spirit_baptized === "No"
                              }
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>
                      {/* SPEAKING IN TONGUES */}
                      <div className="member-form-column">
                        <label htmlFor="">Speaking in Tongues</label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="speaking_in_tongues"
                              value="Yes"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={
                                updateData?.speaking_in_tongues
                                  ? updateData?.speaking_in_tongues === "Yes"
                                  : data?.speaking_in_tongues === "Yes"
                              }
                            />{" "}
                            Yes
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="speaking_in_tongues"
                              value="No"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={
                                updateData?.speaking_in_tongues
                                  ? updateData?.speaking_in_tongues === "No"
                                  : data?.speaking_in_tongues === "No"
                              }
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* FAMILY INFORMATION */}
              <div className="member-form-section">
                <div className="member-form-section-header">
                  FAMILY INFORMATION
                </div>
                <div className="member-form-body">
                  <div className="member-form-details">
                    {/* FIRST ROW */}
                    <div className="member-form-row">
                      {/* NAME OF SPOUSE */}
                      <div className="member-form-column">
                        <label htmlFor="">Name of Spouse</label>
                        <input
                          type="text"
                          name="spouse_name"
                          id="spouse_name"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.spouse_name
                              ? updateData?.spouse_name
                              : data?.spouse_name
                          }
                        />
                      </div>
                      {/* DATE OF MARRIAGE */}
                      <div className="member-form-column">
                        <label htmlFor="">Date of Marriage</label>
                        <input
                          type="date"
                          name="date_of_marriage"
                          id="date_of_marriage"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.date_of_marriage
                              ? updateData?.date_of_marriage
                              : data?.date_of_marriage
                          }
                        />
                      </div>
                    </div>
                    {/* SECOND ROW */}
                    <div className="member-form-row">
                      {/* TYPE OF MARRIAGE */}
                      <div className="member-form-column">
                        <label htmlFor="">Type of Marriage</label>
                        <input
                          type="text"
                          name="type_of_marriage"
                          id="type_of_marriage"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.type_of_marriage
                              ? updateData?.type_of_marriage
                              : data?.type_of_marriage
                          }
                        />
                      </div>
                      {/* SPOUSE CHURCH */}
                      <div className="member-form-column">
                        <label htmlFor="">Spouse Church</label>
                        <input
                          type="text"
                          name="spouse_church"
                          id="spouse_church"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.spouse_church
                              ? updateData?.spouse_church
                              : data?.spouse_church
                          }
                        />
                      </div>
                    </div>
                    {/* THIRD ROW */}
                    <div className="member-form-row">
                      {/* OCCUPATION OF SPOUSE */}
                      <div className="member-form-column">
                        <label htmlFor="">Occupation of spouse</label>
                        <input
                          type="text"
                          name="occupation_of_spouse"
                          id="occupation_of_spouse"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.occupation_of_spouse
                              ? updateData?.occupation_of_spouse
                              : data?.occupation_of_spouse
                          }
                        />
                      </div>
                      {/* SPOUSE CONTACT NUMBER*/}
                      <div className="member-form-column">
                        <label htmlFor="">Spouse Contact Number</label>
                        <input
                          type="text"
                          name="spouse_contact_number"
                          id="spouse_contact_number"
                          onChange={(e) => handleChange(e)}
                          value={
                            updateData?.spouse_contact_number
                              ? updateData?.spouse_contact_number
                              : data?.spouse_contact_number
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* CHILDREN / DEPENDANTS */}
              <div className="member-form-section">
                <div className="member-form-section-header">
                  CHILDREN / DEPENDENTS
                </div>
                <div className="member-form-body">
                  {
                    user?.role === "Administrator" && (
                      <div
                        className="default-button"
                        style={{ marginBottom: "10px" }}
                        onClick={()=>setAddChildModal(true)}
                      >
                        Add Child
                      </div>
                    )
                  }

{
                    user?.role === "Administrative Assistant" && (
                      <div
                        className="default-button"
                        style={{ marginBottom: "10px" }}
                        onClick={()=>setAddChildModal(true)}
                      >
                        Add Child
                      </div>
                    )
                  }
                  <div className="member-form-details">
                    <table style={{ borderCollapse: "collapse" }}>
                      <thead>
                        <tr>
                          <th
                            style={{ border: "1px solid #eee", padding: "5px" }}
                          >
                            SRL
                          </th>
                          <th
                            style={{ border: "1px solid #eee", padding: "5px" }}
                          >
                            NAME
                          </th>
                          <th
                            style={{ border: "1px solid #eee", padding: "5px" }}
                          >
                            DATE OF BIRTH
                          </th>
                          <th
                            style={{ border: "1px solid #eee", padding: "5px" }}
                          >
                            RELATIONSHIP
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberChildren?.map((child, index) => (
                          <tr key={child.id}>
                            <td
                              style={{
                                border: "1px solid #eee",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              style={{
                                border: "1px solid #eee",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              {child.name}
                            </td>
                            <td
                              style={{
                                border: "1px solid #eee",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              {child.date_of_birth}
                            </td>
                            <td
                              style={{
                                border: "1px solid #eee",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              {child.relationship}
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
          {updating ? (
            <>
              <div className="continue-button">
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#030166"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            </>
          ) : (
            <>
              {memberDetails?.is_permanent_member ? (
                <div className="continue-button">
                  <div className="default-button" onClick={() => setPage(2)}>
                    Next Page
                  </div>
                </div>
              ) : (
                <div className="continue-button">
                  <div
                    className="default-button"
                    onClick={() => approveMembership()}
                  >
                    Approve Membership
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {/* PAGE 2 */}
      {page === 2 && (
        <MemberDetails2
          setPage={setPage}
          handleChange={handleChange}
          data={data}
          updateData={updateData}
          updateDetails={updateDetails}
        />
      )}
    </div>
  );
};

export default MemberDetails;
