import React, { useState, useEffect, useContext } from "react";
import "./Dashboard.scss";
import Loading from "../../components/Loading/Loading";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { api_url } from "../../data";
import { toast } from "react-toastify";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import Swal from "sweetalert2";

ChartJS.register(CategoryScale, LinearScale, BarElement);

const Dashboard = ({ openSidebar, setOpenSidebar }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [financialData, setFinancialData] = useState(null);
  const {user} = useContext(AuthContext)
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  // GET DASHBOARD DATA
  const getDashboardData = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .get(`${api_url}/accounts/dashboard-data/`, config)
          .then((response) => {
            setDashboardData(response.data);
            setFinancialData({
              labels,
              datasets: [
                {
                  label: "Income",
                  data: response.data?.income_data.map((data) => data.amount),
                  backgroundColor: "#030166",
                  barThickness: 5,
                },
                {
                  label: "Expense",
                  data: response.data?.expense_data.map((data) => data.amount),
                  backgroundColor: "#03016666",
                  barThickness: 5,
                },
              ],
            });
          });
      } catch (error) {
        toast.error("An error occurred while getting data");
      }
    }
  };

  useEffect(() => {
    if(!user.church_details){
      Swal.fire({
        title:"Church Details Not Set",
        text:"You have not set your church details, please do so before you continue",
        icon:"info"
      }).then(()=>{
        window.location.href = "/settings"
      })
    }else{
      getDashboardData();
    }

  }, []);

  return (
    <div id="main-layout">
      <Sidebar />
      <div id="main-page">
        <Topbar title="Dashboard" />
        
          {!dashboardData ? (
            <Loading />
          ) : (
            <div id="dashboard">
              <div className="dashboard-cards">
                {/* BALANCE CARD */}
                <div className="dashboard-card">
                  <div className="dashboard-card-title">Total Members</div>
                  <div className="dashboard-card-data">
                    {dashboardData?.total_members}
                  </div>
                  <div className="dashboard-card-bottom">Overall</div>
                </div>

                {/* INCOME CARD */}
                <div className="dashboard-card">
                  <div className="dashboard-card-title">New Members</div>
                  <div className="dashboard-card-data">
                    {dashboardData?.new_members}
                  </div>
                  <div className="dashboard-card-bottom">this month</div>
                </div>

                {/* EXPENSE CARD */}
                <div className="dashboard-card">
                  <div className="dashboard-card-title">Net Income</div>
                  <div className="dashboard-card-data">
                    GHS {dashboardData?.total_balance}
                  </div>
                  <div className="dashboard-card-bottom">this month</div>
                </div>
              </div>
              <div className="dashboard-middle-cards">
                <div className="dashboard-middle-card">
                  <div
                    className="dashboard-middle-card-header"
                    style={{ borderBottom: "1px solid #0000004D" }}
                  >
                    <p>Upcoming Events</p>
                    {/* <Link to="/website-resource" className="view-all-button">
                      View All
                    </Link> */}
                  </div>
                  <div className="dashboard-middle-card-body">
                    <div className="upcoming-events">
                      {dashboardData?.upcoming_events.length <= 0 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          There are no upcoming events
                        </div>
                      )}
                      {dashboardData?.upcoming_events.map(
                        (eventItem, index) => (
                          <div className="upcoming-event" key={index}>
                            <div className="event-date">
                              <div className="event-dot"></div>
                              <div className="event-day">{eventItem.day}</div>
                              <div className="event-weekday">
                                {eventItem.month}
                              </div>
                            </div>
                            <div className="event-details">
                              <p className="event-title">{eventItem.title}</p>
                              <p className="event-time">{eventItem.time}</p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="dashboard-middle-card">
                  <div className="dashboard-middle-card-header">
                    <p className="statistics-card-header-title">Statistics</p>
                    <div className="legends">
                      <div className="legend">
                        <div
                          className="legend-box"
                          style={{ backgroundColor: "#030166" }}
                        ></div>
                        <span className="legend-name">Income</span>
                      </div>
                      <div className="legend">
                        <div
                          className="legend-box"
                          style={{ backgroundColor: "#03016666" }}
                        ></div>
                        <span className="legend-name">Expenses</span>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-income-stats">
                    {financialData && (
                      <Bar data={financialData} options={options} />
                    )}
                  </div>
                </div>
              </div>
              <div className="bottom-cards">
                {/* SERMON CARD */}
                <div className="bottom-card">
                  <div className="bottom-card-header">RECENT SERMONS</div>
                  <div className="bottom-card-body">
                    <div className="now-playing">Now Playing</div>
                    <h3>Awesome God</h3>
                    <audio
                      style={{ width: "-webkit-fill-available;" }}
                      controls
                    >
                      <source src="horse.ogg" type="audio/ogg" />
                      <source src="horse.mp3" type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>
                {/* ATTENDANCE CARD */}
                <div className="bottom-card">
                  <div className="bottom-card-header">RECENT SERMONS</div>
                  <div className="bottom-card-body">
                    <div className="now-playing">Now Playing</div>
                    <h3>Awesome God</h3>
                    <audio src="">audio</audio>
                  </div>
                </div>
                {/* MAIL BOX CARD */}
                <div className="bottom-card">
                  <div className="bottom-card-header">RECENT SERMONS</div>
                  <div className="bottom-card-body">
                    <div className="now-playing">Now Playing</div>
                    <h3>Awesome God</h3>
                    <audio src="">audio</audio>
                  </div>
                </div>
              </div>
            </div>
          )}
        
      </div>
    </div>
  );
};

export default Dashboard;
