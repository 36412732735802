import React, {useState, useEffect} from 'react'
import './WebsiteResource.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Topbar from '../../components/topbar/Topbar'
import ThemeList from './Theme/ThemeList'
import SermonList from './Sermon/SermonList'
import AddThemeModal from './Theme/AddThemeModal'
import AddSermonModal from './Sermon/AddSermonModal'
import axios from 'axios'
import { api_url } from '../../data'
import EventList from './Event/EventList'
import AddEventModal from './Event/AddEventModal'

const WebsiteResource = () => {
    const [activeTab, setActiveTab] = useState(1)
    const [addThemeModal, setAddThemeModal] = useState(false)
    const [addSermonModal, setAddSermonModal] = useState(false)
    const [addEventModal, setAddEventModal] = useState(false)
    const [themeList, setThemeList] = useState([])
    const [sermonList, setSermonList] = useState([])
    const [eventList, setEventList] = useState([])

    // GET THEME LIST
    const getThemeList = async () => {
        if(localStorage.getItem('access')){
            const config = {
                headers:{
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('access')
                }
            }
            try {
                await axios.get(`${api_url}/resource/theme/`, config).then((response)=>{
                    setThemeList(response.data)
                })
            } catch (error) {
                
            }
        }
    }

    // GET SERMON LIST
    const getSermonList = async () => {
        if(localStorage.getItem('access')){
            const config = {
                headers:{
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('access')
                }
            }
            try {
                await axios.get(`${api_url}/resource/sermon/`, config).then((response)=>{
                    setSermonList(response.data)
                })
            } catch (error) {
                
            }
        }
    }

    // GET EVENT LIST
    const getEventList = async () => {
        if(localStorage.getItem('access')){
            const config = {
                headers:{
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('access')
                }
            }
            try {
                await axios.get(`${api_url}/resource/event/`, config).then((response)=>{
                    setEventList(response.data)
                })
            } catch (error) {
                
            }
        }
    }

    useEffect(()=>{
        getThemeList();
        getSermonList();
        getEventList();
    },[])
  return (
    <div id='main-layout'>
        {
            addThemeModal && <AddThemeModal modal = {addThemeModal} setModal = {setAddThemeModal} getThemeList={getThemeList} />
        }
        {
            addSermonModal && <AddSermonModal modal={addSermonModal} setModal={setAddSermonModal} getSermonList={getSermonList} />
        }
        {
            addEventModal && <AddEventModal modal={addEventModal} setModal={setAddEventModal} getEventList={getEventList} />
        }
        <Sidebar />
        <div id="main-page">
            <Topbar title="Website Resource" />
            <div id="finance">
                <div className="tabs">
                    <div onClick={() => setActiveTab(1)} className={`${activeTab === 1 ? "tab tab-active" : "tab"}`}>Theme</div>
                    <div onClick={() => setActiveTab(2)} className={`${activeTab === 2 ? "tab tab-active" : "tab"}`}>Vision &amp; Mission</div>
                    <div onClick={() => setActiveTab(3)} className={`${activeTab === 3 ? "tab tab-active" : "tab"}`}>Sermon</div>
                    <div onClick={() => setActiveTab(4)} className={`${activeTab === 4 ? "tab tab-active" : "tab"}`}>Events</div>
                </div>
                <div className="finance-main-page">
                    {
                        activeTab === 1 && (
                            <ThemeList modal = {addThemeModal} setModal = {setAddThemeModal} themeList={themeList} />
                        )
                    }
                    {
                        activeTab === 3 && (
                            <SermonList modal = {addSermonModal} setModal = {setAddSermonModal} sermonList={sermonList} />
                        )
                    }
                    {
                        activeTab === 4 && (
                            <EventList modal = {addEventModal} setModal = {setAddEventModal} eventList={eventList} />
                        )
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default WebsiteResource