import React from "react";
import { ThreeDots } from "react-loader-spinner";


const MemberForm2 = ({ setPage, submitForm, data, handleChange,submitting }) => {
  return (
    <div className="add-member-form">
      {/* FAMILY INFORMATION (CONT'D) */}
      <div className="member-form-section">
        <div className="member-form-section-header">
          FAMILY INFORMATION (CONT’D)
        </div>
        <div className="member-form-body">
          <div className="member-form-details">
            {/* FIRST ROW */}
            <div className="member-form-row">
              {/* FATHER'S NAME */}
              <div className="member-form-column">
                <label htmlFor="">Father's Name<span class="text-danger">*</span></label>
                <input type="text" onChange={(e) => handleChange(e)} value={data?.father_name} name="father_name" id="father_name" />
              </div>
              {/* FATHER'S CONTACT NUMBER */}
              <div className="member-form-column">
                <label htmlFor="">Father's Contact Number</label>
                <input
                  type="text"
                  name="father_contact_number"
                  id="father_contact_number"
                  onChange={(e) => handleChange(e)} 
                  value={data?.father_contact_number}
                />
              </div>
              {/* ADDRESS */}
              <div className="member-form-column">
                <label htmlFor="">Address</label>
                <input type="text" name="father_address" id="father_address"
                    onChange={(e) => handleChange(e)} 
                    value={data?.father_address}
                />
              </div>
            </div>
            {/* SECOND ROW */}
            <div className="member-form-row">
              {/* FATHER OCCUPATION */}
              <div className="member-form-column">
                <label htmlFor="">Father's Occupation</label>
                <input
                  type="text"
                  name="father_occupation"
                  id="father_occupation"
                  onChange={(e) => handleChange(e)} 
                    value={data?.father_occupation}
                />
              </div>
              {/* FATHER HOMETOWN */}
              <div className="member-form-column">
                <label htmlFor="">Father's Hometown</label>
                <input
                  type="text"
                  name="father_hometown"
                  id="father_hometown"
                  onChange={(e) => handleChange(e)} 
                    value={data?.father_hometown}
                />
              </div>
              {/* FATHER NATIONALITY */}
              <div className="member-form-column">
                <label htmlFor="">Father's Nationality</label>
                <input
                  type="text"
                  name="father_nationality"
                  id="father_nationality"
                  onChange={(e) => handleChange(e)} 
                  value={data?.father_nationality}
                />
              </div>
            </div>
            {/* THIRD ROW */}
            <div className="member-form-row">
              {/* MOTHER'S NAME */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Name <span class="text-danger">*</span></label>
                <input type="text" name="mother_name" id="mother_name" 
                onChange={(e) => handleChange(e)} 
                  value={data?.mother_name} />
              </div>
              {/* MOTHER'S CONTACT NUMBER */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Contact Number</label>
                <input
                  type="text"
                  name="mother_contact_number"
                  id="mother_contact_number"
                  onChange={(e) => handleChange(e)} 
                  value={data?.mother_contact_number}
                />
              </div>
              {/* MOTHER'S ADDRESS */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Address</label>
                <input type="text" name="mother_address" id="mother_address"
                    onChange={(e) => handleChange(e)} 
                    value={data?.mother_address}
                />
              </div>
            </div>
            {/* FOURTH ROW */}
            <div className="member-form-row">
              {/* MOTHER'S OCCUPATION */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Occupation</label>
                <input
                  type="text"
                  name="mother_occupation"
                  id="mother_occupation"
                  onChange={(e) => handleChange(e)} 
                  value={data?.mother_occupation}
                />
              </div>
              {/* MOTHER'S HOMETOWN */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Hometown</label>
                <input
                  type="text"
                  name="mother_hometown"
                  id="mother_hometown"
                  onChange={(e) => handleChange(e)} 
                  value={data?.mother_hometown}
                />
              </div>
              {/* MOTHER'S NATIONALITY */}
              <div className="member-form-column">
                <label htmlFor="">Mother's Nationality</label>
                <input type="text" name="mother_nationality" 
                    onChange={(e) => handleChange(e)} 
                    value={data?.mother_nationality}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EDUCATION & WORK INFORMATION */}
      <div className="member-form-section">
        <div className="member-form-section-header">
          EDUCATION & WORK INFORMATION
        </div>
        <div className="member-form-body">
          <div className="member-form-details">
            {/* FIRST ROW */}
            <div className="member-form-row">
              {/* HIGHEST EDUCATION QUALIFICATION */}
              <div className="member-form-column">
                <label htmlFor="">Highest Education Qualification<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="education_qualification"
                  id="education_qualification"
                  onChange={(e) => handleChange(e)} 
                  value={data?.education_qualification}
                />
              </div>
            </div>
            {/* SECOND ROW */}
            <div className="member-form-row">
              {/* PROFESSION */}
              <div className="member-form-column">
                <label htmlFor="">Profession<span class="text-danger">*</span></label>
                <input type="text" name="profession" id="profession" 
                    onChange={(e) => handleChange(e)} 
                    value={data?.profession}
                />
              </div>
              {/* OCCUPATION */}
              <div className="member-form-column">
                <label htmlFor="">Occupation<span class="text-danger">*</span></label>
                <input type="text" name="occupation" id="occupation"
                    onChange={(e) => handleChange(e)} 
                    value={data?.occupation}
                />
              </div>
            </div>
            {/* THIRD ROW */}
            <div className="member-form-row">
              {/* PLACE OF WORK */}
              <div className="member-form-column">
                <label htmlFor="">Place of work<span class="text-danger">*</span></label>
                <input type="text" name="place_of_work" id="place_of_work"
                    onChange={(e) => handleChange(e)} 
                    value={data?.place_of_work}
                />
              </div>
              {/* CONTACT NUMBER */}
              <div className="member-form-column">
                <label htmlFor="">Contact Number</label>
                <input type="text" name="work_contact" id="work_contact" 
                    onChange={(e) => handleChange(e)} 
                    value={data?.work_contact}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GENERAL INFORMATION */}
      <div className="member-form-section">
        <div className="member-form-section-header">GENERAL INFORMATION</div>
        <div className="member-form-body">
          <div className="member-form-details">
            {/* FIRST ROW */}
            <div className="member-form-row">
              {/* NAME OF SPOUSE */}
              <div className="member-form-column">
                <label htmlFor="">Next of Kin<span class="text-danger">*</span></label>
                <input type="text" name="next_of_kin" id="next_of_kin"
                    onChange={(e) => handleChange(e)} 
                    value={data?.next_of_kin}
                />
              </div>
              {/*RELATIONSHIP */}
              <div className="member-form-column">
                <label htmlFor="">Relationship<span class="text-danger">*</span></label>
                <input type="text" name="relationship" id="relationship" 
                    onChange={(e) => handleChange(e)} 
                    value={data?.relationship}
                />
              </div>
            </div>
            {/* SECOND ROW */}
            <div className="member-form-row">
              {/*NOK ADDRESS*/}
              <div className="member-form-column">
                <label htmlFor="">Next of Kin Address<span class="text-danger">*</span></label>
                <input type="text" name="address_of_nok" id="address_of_nok" 
                    onChange={(e) => handleChange(e)} 
                    value={data?.address_of_nok}
                />
              </div>
              {/* NOK CONTACT NUMBER */}
              <div className="member-form-column">
                <label htmlFor="">Next Of Kin Contact Number<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="nok_contact_number"
                  id="nok_contact_number"
                  onChange={(e) => handleChange(e)} 
                    value={data?.nok_contact_number}
                />
              </div>
            </div>
            {/* THIRD ROW */}
            <div className="member-form-row">
              {/* EMERGENCY CONTACT PERSON */}
              <div className="member-form-column">
                <label htmlFor="">Emergency Contact Person<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="emergency_contact_person"
                  id="emergency_contact_person"
                  onChange={(e) => handleChange(e)} 
                    value={data?.emergency_contact_person}
                />
              </div>
              {/* EMERGENCY CONTACT PERSON'S RELATIONSHIP*/}
              <div className="member-form-column">
                <label htmlFor="">
                  Emergency Contact Person's Relationship
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="emergency_person_relationship"
                  id="emergency_person_relationship"
                  onChange={(e) => handleChange(e)} 
                    value={data?.emergency_person_relationship}
                />
              </div>
            </div>
            {/* FOURTH ROW */}
            <div className="member-form-row">
              {/* EMERGENCY CONTACT PERSON ADDRESS */}
              <div className="member-form-column">
                <label htmlFor="">Emergency Contact Person's Address<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="emergency_contact_person_address"
                  id="emergency_contact_person_address"
                  onChange={(e) => handleChange(e)} 
                    value={data?.emergency_contact_person_address}
                />
              </div>
              {/* EMERGENCY CONTACT PERSON'S CONTACT*/}
              <div className="member-form-column">
                <label htmlFor=""> Contact Number<span class="text-danger">*</span></label>
                <input
                  type="text"
                  name="emergency_person_contact"
                  id="emergency_person_contact"
                  onChange={(e) => handleChange(e)} 
                    value={data?.emergency_person_contact}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
        {
            submitting ? (
                <div className="continue-button">
                    <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#030166"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
            ): (
                <div className="continue-button">
                    <div className="default-button" onClick={() => setPage(1)}>
                    Previous
                    </div>
                    <div className="default-button" onClick={(e) => submitForm(e)}>
                    Submit
                    </div>
                </div>
            )
        }
    </div>
  );
};

export default MemberForm2;
