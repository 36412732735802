import React, { useContext, useEffect, useState } from "react";
import "./Finance.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import { Bar, Pie } from "react-chartjs-2";
import { api_url } from "../../data.js";
import { TfiStatsUp } from "react-icons/tfi";
import { AiOutlineWallet } from "react-icons/ai";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import axios from "axios";
import IncomeList from "./IncomeList";
import ExpenseList from "./ExpenseList";
import AddIncomeModal from "./AddIncomeModal";
import AddExpenseModal from "./AddExpenseModal";
import BarChart from "../../components/Charts/BarChart";
import Loading from "../../components/Loading/Loading";
import ProfitLossStatement from "./ProfitLossStatement";
import AuthContext from "../../context/AuthContext.js";
import Swal from "sweetalert2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Finance = () => {
  const {user} = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState(1);
  const [incomeList, setIncomeList] = useState([])
  const [expenseList,setExpenseList] = useState([])
  const [incomeData, setIncomeData] = useState({})
  const [expenseData, setExpenseData] = useState({})
  const [overviewData, setOverviewData] = useState({})
  const [incomeModal, setIncomeModal] = useState(false)
  const [expenseModal, setExpenseModal] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const [expenseTypes, setExpenseTypes] = useState([])
  const [incomeTypes, setIncomeTypes] = useState([])
  const [pieChartData, setPieChartData] = useState([])

  // GET INCOME LIST
  const getIncomeList = async () => {
    if(localStorage.getItem("access")){
      const config = {
        headers:{
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access")
        }
      }
      try {
        await axios.get(`${api_url}/finance/income/`,config).then((response) => {
          setIncomeList(response.data)
        })
        
      } catch (error) {
        console.log(error)
      }
    }
  }

  // GET INCOME LIST
  const getExpenseList = async () => {
    if(localStorage.getItem("access")){
      const config = {
        headers:{
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access")
        }
      }
      try {
        await axios.get(`${api_url}/finance/expense/`,config).then((response) => {
          setExpenseList(response.data)
        })
        
      } catch (error) {
        console.log(error)
      }
    }
  }

  // GET OVERVIEW DATA
  const getFinancialOverview = async () => {
    if(localStorage.getItem("access")){
      const config = {
        headers:{
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access")
        }
      }
      try {
        const response = await axios.get(`${api_url}/finance/overview/`,config)
          
        setIncomeData({
            labels: response.data?.income_data.map((data) => data.month),
            datasets: [
              {
                label: "Income",
                data: response.data?.income_data.map((data) => data.amount),
                backgroundColor: "#79AC78",
              },
            ],
          });

          setExpenseData({
            labels: response.data?.expense_data.map((data) => data.month),
            datasets: [
              {
                label: "Income",
                data: response.data?.expense_data.map((data) => data.amount),
                backgroundColor: "#FF6969",
              },
            ],
          });

          setPieChartData({
            labels: ["Income", "Expense"],
            datasets: [
              {
                label: "Total",
                data: [response.data.total_income, response.data.total_expenses],
                backgroundColor: ["#599BF2", "#9747FF"],
              }
            ]
          })
          
          setOverviewData(response.data)
          getIncomeList();
          getExpenseList();
          setDataFetched(true)
        
      } catch (error) {
        console.log(error)
      }
    }
  }

  // GET EXPENSE TYPES
  const getExpenseTypes = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .get(`${api_url}/finance/expense-types/`, config)
          .then((response) => {
            if (response.status === 200) {
              setExpenseTypes(response.data)
            }
          });
      } catch (error) {
      }
    }
  }

  // GET EXPENSE TYPES
  const getIncomeTypes = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      };
      try {
        await axios
          .get(`${api_url}/finance/income-types/`, config)
          .then((response) => {
            if (response.status === 200) {
              setIncomeTypes(response.data)
            }
          });
      } catch (error) {
      }
    }
  }

  useEffect(()=>{
    if(!user.church_details){
      Swal.fire({
        title:"Church Details Not Set",
        text:"You have not set your church details, please do so before you continue",
        icon:"info"
      }).then(()=>{
        window.location.href = "/settings"
      })
    }else{
      getFinancialOverview();
      getExpenseTypes();
      getIncomeTypes();
    }
  },[])

  if(dataFetched){
    return (
      <div id="main-layout">
          {
              incomeModal && <AddIncomeModal incomeTypes = {incomeTypes} modal={incomeModal} setModal={setIncomeModal} getIncomeList={getIncomeList} />
          }
          {
              expenseModal && <AddExpenseModal expenseTypes = {expenseTypes} modal={expenseModal} setModal={setExpenseModal} getExpenseList={getExpenseList} />
          }
        <Sidebar />
        <div id="main-page">
          <Topbar title="FINANCE" />
          <div id="finance">
            <div className="tabs">
              <div
                className={`${activeTab === 1 ? "tab tab-active" : "tab"}`}
                onClick={() => setActiveTab(1)}
              >
                Overview
              </div>
              <div
                className={`${activeTab === 2 ? "tab tab-active" : "tab"}`}
                onClick={() => setActiveTab(2)}
              >
                Income
              </div>
              <div
                className={`${activeTab === 3 ? "tab tab-active" : "tab"}`}
                onClick={() => setActiveTab(3)}
              >
                Expenses
              </div>
              <div
                className={`${activeTab === 4 ? "tab tab-active" : "tab"}`}
                onClick={() => setActiveTab(4)}
              >
                Profit &amp; Loss Statement
              </div>
            </div>
            <div className="finance-main-page">
              {activeTab === 1 && (
                <div className="overview-page">
                  <div className="overview-left">
                    <div className="finance-cards">
                      {/* TOTAL BALANCE */}
                      <div className="finance-card">
                        <div className="finance-card-icon">
                          <AiOutlineWallet />
                        </div>
                        <div className="finance-card-title">Total Balance</div>
                        <div className="finance-card-details">
                          <TfiStatsUp /> <span>GHS {overviewData?.net_income}</span>
                        </div>
                      </div>
                      {/* TOTAL BALANCE */}
                      <div className="finance-card">
                        <div className="finance-card-icon">
                          <AiOutlineWallet />
                        </div>
                        <div className="finance-card-title">Total Income</div>
                        <div className="finance-card-details">
                          <TfiStatsUp /> <span>GHS {overviewData?.total_income}</span>
                        </div>
                      </div>
                      {/* TOTAL BALANCE */}
                      <div className="finance-card">
                        <div className="finance-card-icon">
                          <AiOutlineWallet />
                        </div>
                        <div className="finance-card-title">Total Expenses</div>
                        <div className="finance-card-details">
                          <TfiStatsUp /> <span>GHS {overviewData?.total_expenses}</span>
                        </div>
                      </div>
                    </div>
  
                    {/* EXPENSE CHART */}
                    <div className="stats-chart">
                      <p className="title">Expenses Stats</p>
                      <p className="amount">This Year</p>
                      <div className="chart">
                        <BarChart  chartData={expenseData} />
                      </div>
                    </div>
  
                    {/* INCOME STATS */}
                    <div className="stats-chart">
                      <p className="title">Income Stats</p>
                      <p className="amount">This Year</p>
                      <div className="chart">
                        <BarChart chartData={incomeData} />
                        
                      </div>
                    </div>
                  </div>
                  <div className="overview-right">
                    {/* FINANCE PIE CHART */}
                    <div className="overview-right-card">
                      <h3>Total Balance</h3>
                      <div className="pie-chart">
                        <Pie data={pieChartData} />
                      </div>
                    </div>
  
                    {/* LATEST TRANSACTION */}
                    {/* <div className="overview-right-card">
                      <h3>Latest Transactions</h3>
                      <div className="transaction">
                        <div className="details">
                          <h4>Recent Transaction</h4>
                          <p>14-06-2023</p>
                        </div>
                        <div className="amount">
                          <p>GHS 100.00</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
  
              {activeTab === 2 && (
                <IncomeList incomeTypes = {incomeTypes} incomeList = {incomeList} modal={incomeModal} setModal={setIncomeModal} />
              )}
  
              {activeTab === 3 && (
                <ExpenseList expenseTypes={expenseTypes} expenseList = {expenseList} modal={expenseModal} setModal={setExpenseModal} />
              )}

              {
                activeTab === 4 && (
                  <ProfitLossStatement />
                )
              }
            </div>
          </div>
        </div>
      </div>
    );

  }else{
    return (
      <Loading />
    )
  }
};

export default Finance;
