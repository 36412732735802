import React from 'react'
import './AboutPage.scss'
import Footer from '../../WebsiteComponents/Footer/Footer'
import Navbar from '../../WebsiteComponents/Navbar/Navbar'

// ASSETS
import AboutCarouselOne from "../../assets/aboutImages/aboutCarouselOne.png"
import LeaderImage from "../../assets/aboutImages/leaderImage.png"

const AboutPage = () => {
  return (
    <div>
        <Navbar />
        <div id="about-page">
            <div className="about-us">
                <div className="about-carousel">
                    <img src={AboutCarouselOne} alt="royal evangelical" />
                </div>
                <div className="about-text">
                    <h3>About Us</h3>
                    <p>
                    The vision of discovery is to be a church of grace-filled people, so that the Gospel is transferred from generation to generation.
                    </p>
                    <p>
                    We believe God has a purpose for everyone and that none of us are called to sit on the sidelines. As a church, we want to do whatever we can to help people walk closer to God and experience the life and the hope that He has for them. We have something for everyone
                    </p>
                    <p>
                    We will dig into topics such as salvation and repentance, our identity in Christ, personal spiritual practices, how to steward everything God has entrusted to us, how to share our stories with others, and we will explore the core beliefs of our Christian faith.
                    </p>
                </div>
            </div>
            <div className="our-leaders">
                <h3>Our Leaders</h3>
                <div className="leaders">
                    <div className="leader">
                        <div className="leader-img">
                        <img src={LeaderImage} alt="" />
                        </div>
                        <div className="leader-details">
                            <p className="name">Godsway Dogbe</p>
                            <p className="position">Church President</p>
                        </div>
                    </div>
                    <div className="leader">
                        <div className="leader-img">
                        <img src={LeaderImage} alt="" />
                        </div>
                        <div className="leader-details">
                            <p className="name">Godsway Dogbe</p>
                            <p className="position">Church President</p>
                        </div>
                    </div>
                    <div className="leader">
                        <div className="leader-img">
                            <img src={LeaderImage} alt="" />
                        </div>
                        <div className="leader-details">
                            <p className="name">Godsway Dogbe</p>
                            <p className="position">Church President</p>
                        </div>
                    </div>
                    <div className="leader">
                        <div className="leader-img">
                        <img src={LeaderImage} alt="" />
                        </div>
                        <div className="leader-details">
                            <p className="name">Godsway Dogbe</p>
                            <p className="position">Church President</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default AboutPage