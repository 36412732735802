import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Image1 from "../../assets/CarouselImages/carouselOne.png";
import Image2 from "../../assets/CarouselImages/carouselTwo.png";
import Image3 from "../../assets/CarouselImages/carouselThree.png";
import './Carousel.scss'

const Carousel = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }
  };

  const slides = [
    {
      url: Image1,
      subtitle: "Ignite Your Faith at",
      title: "Royal Evangelical Church",
    },
    {
        url: Image2,
        subtitle: "Experience God’s ",
        title: "Love & Grace ",
        bottomText:"Through Worship"
      },
      {
        url: Image3,
        subtitle: "Deepen Your Faith ",
        title: "Through Inspiring Sermons",
      },
  ];

  return (
    <div id="carousel">
      <IoIosArrowBack onClick={() => handleClick("right")} />
      <div
        className="wrapper"
        style={{ transform: `translateX(${slideIndex * -100}vw)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className="slide"
            style={{
              backgroundImage: `url(${slide.url}), linear-gradient(to right,rgba(255,255, 255, 0.8) 20%, rgba(0, 0, 0, .5) 100%)`,
            }}
          >
            <div className="carousel-text">
              <h3>{slide.subtitle}</h3>
              <h1>{slide.title}</h1>
              <h4>{slide.bottomText}</h4>
            </div>
          </div>
        ))}
      </div>
      <IoIosArrowForward
        style={{ right: "10px" }}
        onClick={() => handleClick("right")}
      />
    </div>
  );
};

export default Carousel;
