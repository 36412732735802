import React,{useEffect, useState} from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import ChurchSettings from "./ChurchSettings";
import PersonalSettings from "./PersonalSettings";
import './Settings.scss'
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../data";
import ChurchSms from "./ChurchSms";


const Settings = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [churchDetails, setChurchDetails] = useState(null)

    const getChurchDetails = async () => {
      if(localStorage.getItem("access")){
        
        const config = {
          headers:{
            "Content-Type": "application/json",
            Authorization:"Bearer " + localStorage.getItem("access")
          }
        }
        
        try {
          await axios.get(`${api_url}/accounts/church-details/`, config).then((response)=>{
            if(response.status === 200){
              
              setChurchDetails(response.data)
            }
          })
        } catch (error) {
          toast.error("An error occurred.")
        }
      }
    }

    useEffect(()=>{
      getChurchDetails()
    },[])
  return (
    <div id="main-layout">
      <Sidebar />
      <div id="main-page">
        <Topbar title="Settings" />
        <div id="finance">
          <div className="tabs">
            <div
              className={`${activeTab === 1 ? "tab tab-active" : "tab"}`}
              onClick={() => setActiveTab(1)}
            >
              Church
            </div>
            <div
              className={`${activeTab === 2 ? "tab tab-active" : "tab"}`}
              onClick={() => setActiveTab(2)}
            >
              Profile
            </div>
            <div
              className={`${activeTab === 3 ? "tab tab-active" : "tab"}`}
              onClick={() => setActiveTab(3)}
            >
              SMS
            </div>
          </div>
          {
            activeTab === 1 && (
              <ChurchSettings churchDetails={churchDetails} />
            )
          }
          {
            activeTab === 2 && (
              <PersonalSettings />
            )
          }
          {
            activeTab === 3 && (
              <ChurchSms churchDetails={churchDetails} />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Settings;
