import React from "react";
import Carousel from "../../WebsiteComponents/Carousel/Carousel";
import Navbar from "../../WebsiteComponents/Navbar/Navbar";
import "./HomePage.scss";
import RoyalPastor from "../../assets/royalPastor.png";
import SermonImage from "../../assets/sermonImg.png";
import {BsArrowRightCircle} from "react-icons/bs"
import Timer from "../../WebsiteComponents/Timer/Timer";
import Footer from "../../WebsiteComponents/Footer/Footer";
const HomePage = () => {
  return (
    <div>
      <Navbar />
      <Carousel />
      <div id="year-theme">
        <div className="theme-title">
          <h3>THEME OF THE YEAR</h3>
          <div className="underline"></div>
        </div>
        <p className="theme">
          Royal Evangelical Church is the True Path to Overcoming Suffering &
          Difficulties in Life through Faith & Love
        </p>
      </div>
      <div id="mission-vision-believes">
        <p className="mission-vision-title">VISION & MISSION</p>
        <div className="mission-vision">
          <div className="mission-vision-item">
            <h3 className="mission-vision-item-title">OUR VISION</h3>
            <p className="mission-vision-item-body">
              To be a global family church that glorifies God, and reaches the
              world with the compassion of Christ and the power of the Holy
              Ghost.
            </p>
          </div>
          <div className="mission-vision-item">
            <h3 className="mission-vision-item-title">OUR VISION</h3>
            <p className="mission-vision-item-body">
              To be a global family church that glorifies God, and reaches the
              world with the compassion of Christ and the power of the Holy
              Ghost.
            </p>
          </div>
        </div>
        <div className="believes">
          <h3 className="believes-title">OUR BELIEVES</h3>
          <ul className="believes-list">
            <li>
              We believe in the deity of Jesus Christ, His substitutionary death
              for all men, His resurrection, and His eventual return to judge
              the world. We believe that salvation is available to all who will
              receive Jesus Christ, as their Lord and personal Saviour.
            </li>

            <li>
              We believe in the existence of the Holy Spirit as the Third Person
              of the Trinity. We believe in the baptism of the Holy Spirit.
            </li>

            <li>
              We believe in the Scripture as the inspired Word of God and it is
              the revelation of God’s will for mankind. We believe in divine
              healing and that Jesus is the Healer.
            </li>
          </ul>
        </div>
      </div>
      <div id="welcome-message">
        <div className="welcome-message-left">
          <div className="pastor-img">
            <img src={RoyalPastor} alt="patrick appiah" />
            <div className="welcome-box">
              <h3>Join Our Service For Spiritual Enrichment</h3>
            </div>
          </div>
        </div>
        <div className="welcome-message-right">
          <div className="welcome-title">
            <h3>WELCOME TO</h3>
            <h2>Royal Evangelical Church</h2>
          </div>
          <div className="welcome-text">
            <p className="greeting">Precious one,</p>
            <p className="message">
              We are a community of Bible-believing Christians dedicated to your
              Spiritual, Physical, Economic, and Emotional well-being.
            </p>
          </div>
          <div className="welcome-about">
            <div className="bar"></div>
            <div className="welcome-about-text">
              We are a Charismatic and Pentecostal Church that believes in the
              totality of the Bible and seeks to reach the world through the
              preaching of the gospel, accompanied by the power of God.
            </div>
          </div>
          <div className="meet-pastor-button">MEET REV PATRICK APPIAH</div>
        </div>
      </div>
      <div id="sermons">
        <div className="sermons-title">
          ACCESS OUR SERMONS
        </div>
        <div className="latest-sermon">
          <div className="latest-sermon-top">
            <h3>Latest Of Our Sermons</h3>
            <div className="access-more">
              Access More <BsArrowRightCircle />
            </div>
          </div>
          <div className="latest-sermon-item">
            <div className="latest-sermon-thumbnail">
                <img src={SermonImage} alt="sermon" />
            </div>
            <div className="sermon-main">
                <div className="now-playing-button">
                  NOW PLAYING
                </div>
                <h3 className="sermon-title">
                  Server Somebody: Sing Together
                </h3>
                <audio controls>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
            </div>
          </div>
          <div className="other-sermons">
            <div className="other-sermon-item">
              <div className="other-sermon-thumbnail">
                <img src={SermonImage} alt="sermon" />
              </div>
              <div className="other-sermon-detail">
                <h3 className="title">Nobody greater</h3>
                <p className="preacher">Vashawn Mitchell</p>
              </div>
              <audio className="other-sermon-audio" controls>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
            </div>
            <div className="other-sermon-item">
              <div className="other-sermon-thumbnail">
                <img src={SermonImage} alt="sermon" />
              </div>
              <div className="other-sermon-detail">
                <h3 className="title">Nobody greater</h3>
                <p className="preacher">Vashawn Mitchell</p>
              </div>
              <audio className="other-sermon-audio" controls>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
            </div>
            <div className="other-sermon-item">
              <div className="other-sermon-thumbnail">
                <img src={SermonImage} alt="sermon" />
              </div>
              <div className="other-sermon-detail">
                <h3 className="title">Nobody greater</h3>
                <p className="preacher">Vashawn Mitchell</p>
              </div>
              <audio className="other-sermon-audio" controls>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
            </div>
          </div>
        </div>
      </div>
      <div id="upcoming-event">
        <h4 className="upcoming-event-title">
          Upcoming Event
        </h4>
        <h2 className="event-title">
          Join Our Week of Prayer for Christian Unity Coming on 
        </h2>
        <h2 className="event-date">28th August, 2023  </h2>
        <div className="event-time">
          <Timer initialMinute={1} initialSeconds={59} />
        </div>
        <div className="more-events-button">
          VIEW ALL EVENTS
        </div>
      </div>
      <div id="how-we-worship">
        <h3 className="worship-text">
          Find out How, Where and When We Worship. 
          We Hope to See You Soon!
        </h3>
        <div className="learn-more-button">
          LEARN MORE
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
