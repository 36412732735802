import React from "react";
import Navbar from "../../WebsiteComponents/Navbar/Navbar";
import "./Resources.scss";
import SermonImage from "../../assets/sermonImg.png";
import {BsArrowRightCircle} from "react-icons/bs"

const Resources = () => {
  return (
    <div>
      <Navbar />
      <div id="resources-page">
        <div id="sermons" className="resources-sermons">
          <div className="sermons-title">ACCESS OUR SERMONS</div>
          <div className="latest-sermon">
            <div className="latest-sermon-top">
              <h3>Latest Of Our Sermons</h3>
              <div className="access-more">
                Access More <BsArrowRightCircle />
              </div>
            </div>
            <div className="latest-sermon-item">
              <div className="latest-sermon-thumbnail">
                <img src={SermonImage} alt="sermon" />
              </div>
              <div className="sermon-main">
                <div className="now-playing-button">NOW PLAYING</div>
                <h3 className="sermon-title">Server Somebody: Sing Together</h3>
                <audio controls>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
            <div className="other-sermons">
              <div className="other-sermon-item">
                <div className="other-sermon-thumbnail">
                  <img src={SermonImage} alt="sermon" />
                </div>
                <div className="other-sermon-detail">
                  <h3 className="title">Nobody greater</h3>
                  <p className="preacher">Vashawn Mitchell</p>
                </div>
                <audio className="other-sermon-audio" controls>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div className="other-sermon-item">
                <div className="other-sermon-thumbnail">
                  <img src={SermonImage} alt="sermon" />
                </div>
                <div className="other-sermon-detail">
                  <h3 className="title">Nobody greater</h3>
                  <p className="preacher">Vashawn Mitchell</p>
                </div>
                <audio className="other-sermon-audio" controls>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div className="other-sermon-item">
                <div className="other-sermon-thumbnail">
                  <img src={SermonImage} alt="sermon" />
                </div>
                <div className="other-sermon-detail">
                  <h3 className="title">Nobody greater</h3>
                  <p className="preacher">Vashawn Mitchell</p>
                </div>
                <audio className="other-sermon-audio" controls>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
