import React from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'

const SermonList = ({ modal, setModal, sermonList }) => {
    return (
        <div className='income-page'>
            <div className="add-income">
                <div className="default-button" onClick={() => setModal(!modal)}>Add New Sermon</div>
            </div>
            <div className="default-table">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Preacher</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sermonList.map((sermonItem, index) => (
                                <tr key={index}>
                                    <td>{sermonItem.date}</td>
                                    <td>{sermonItem.title}</td>
                                    <td>{sermonItem.preacher}</td>
                                    <td className='actions'>
                                        <AiOutlineEdit />
                                        <AiOutlineDelete />
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SermonList