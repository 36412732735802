import React from "react";
import "./AddMember.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import { useState } from "react";
import UserImage from "../../assets/userImage.png";
import { BsFillCloudUploadFill } from "react-icons/bs";
import Swal from "sweetalert2";
import MemberForm2 from "./MemberForm2";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../data";

const AddMember = () => {
  const [page, setPage] = useState(1);
  const [file, setFile] = useState(null);
  const [data, setData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [addingChildren, setAddingChildren] = useState(false)
  const [childFormFields, setChildFormFields] = useState([
    {
      srl:"",
      name: "",
      date_of_birth: "",
      gender: "",
      relationship: "",
    },
  ]);

  // CHANGING CHILD FORM FIELDS FOR CHILD DETAILS
  const handleChildFormFieldsChange = (e, index) => {
    const formData = [...childFormFields];
    formData[index][e.target.name] = e.target.value;
    setChildFormFields(formData);
  };

  // ADD MORE CHILDREN
  const addChildFields = () => {
    const object = {
      name: "",
      date_of_birth: "",
      gender: "",
      relationship: "",
    };
    setChildFormFields([...childFormFields, object]);
  };

  // REMOVE CHILD FIELDS
  const removeChildFields = (index) => {
    const formFieldData = [...childFormFields];
    formFieldData.splice(index, 1);
    setChildFormFields(formFieldData);
  };

  // SUBMIT FORM
  const submitForm = async (e) => {
    e.preventDefault()
    if(localStorage.getItem("access")){
      setSubmitting(true)
      const config = {
        headers:{
          "Content-Type": "multipart/form-data",
          Authorization:"Bearer " + localStorage.getItem("access")
        }
      }
      
      const formData = new FormData()
      Object.keys(data).forEach(key=>{
        formData.append(key, data[key])
      })
      
      try {
        await axios.post(`${api_url}/members/`, formData, config).then((response)=>{
          if(childFormFields[0]['name'] === ""){
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Member has been added successfully",
            }).then(()=>{
              window.location.reload()
            });
            setSubmitting(false)
          }else{
            addChildren(response.data.member_id)
          }
        })
      } catch (error) {
        console.log(error)
        setSubmitting(false)
        toast.error("An error occurred.")
      }
    }
    
  };

  // CREATE CHILDREN
  const addChildren = async (member_id) =>{
    if(localStorage.getItem("access")){
      setAddingChildren(true)
      const config = {
        headers:{
          "Content-Type": "application/json",
          Authorization:"Bearer " + localStorage.getItem("access")
        }
      }
      
      const body = JSON.stringify(childFormFields)
      try {
        await axios.post(`${api_url}/members/member-child/${member_id}/`, body, config).then((response)=>{
          if(response.status === 201){
            setAddingChildren(false)
            setSubmitting(false)
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Member has been added successfully",
            }).then(()=>{
              window.location.reload()
            });
          }
        })
      } catch (error) {
        setAddingChildren(false)
        setSubmitting(false)
        toast.error("An error occurred with adding children.")
      }
    }
  }

  // HANDLE CHANGE
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div id="main-layout">
      <Sidebar />
      <div id="main-page">
        <Topbar title="Member Management" />
        <div id="add-member">
          <div className="add-member-title">ADD NEW MEMBER</div>
          {/* PAGE 1 */}
          {page === 1 && (
            <div className="add-member-form">
              {/* PERSONAL INFORMATION */}
              <div className="member-form-section">
                <div className="member-form-section-header">
                  PERSONAL INFORMATION
                </div>
                <div className="personal-info-section member-form-body">
                  <div className="personal-info-details member-form-details">
                    {/* FIRST ROW */}
                    <div className="member-form-row">
                      {/* FIRST NAME */}
                      <div className="member-form-column">
                        <label htmlFor="">First Name
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          value={data?.first_name}
                          type="text"
                          onChange={(e) => handleChange(e)}
                          name="first_name"
                          id="first_name"
                          className="form--control"
                        />
                      </div>
                      {/* LAST NAME */}
                      <div className="member-form-column">
                        <label htmlFor="">Last Name
                        <span class="text-danger">*</span></label>
                        <input
                          type="text"
                          value={data?.last_name}
                          onChange={(e) => handleChange(e)}
                          name="last_name"
                          id="last_name"
                        />
                      </div>
                      {/* GENDER */}
                      <div className="member-form-column">
                        <label htmlFor="">Gender
                        <span class="text-danger">*</span></label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              type="radio"
                              onChange={(e) => handleChange(e)}
                              name="gender"
                              value="Male"
                              defaultChecked={data?.gender === "Male"}
                            />{" "}
                            Male
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              onChange={(e) => handleChange(e)}
                              name="gender"
                              value="Female"
                              defaultChecked={data?.gender === "Female"}
                            />{" "}
                            Female
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* SECOND ROW */}
                    <div className="member-form-row">
                      {/* ADDRESS */}
                      <div className="member-form-column">
                        <label htmlFor="">Address
                        <span class="text-danger">*</span></label>
                        <input
                          type="text"
                          onChange={(e) => handleChange(e)}
                          name="address"
                          id="address"
                          value={data?.address}
                        />
                      </div>
                      {/* Phone Number */}
                      <div className="member-form-column">
                        <label htmlFor="">Phone Number
                        <span class="text-danger">*</span></label>
                        <input
                          type="text"
                          name="phone_number"
                          id="phone_number"
                          onChange={(e) => handleChange(e)}
                          value={data?.phone_number}
                        />
                      </div>
                      {/* EMAIL */}
                      <div className="member-form-column">
                        <label htmlFor="">Email</label>
                        <input
                          type="email"
                          onChange={(e) => handleChange(e)}
                          name="email"
                          id="email"
                          value={data?.email}
                        />
                      </div>
                    </div>
                    {/* THIRD ROW */}
                    <div className="member-form-row">
                      {/* DATE */}
                      <div className="member-form-column">
                        <label htmlFor="">Date of birth
                        <span class="text-danger">*</span></label>
                        <input
                          type="date"
                          name="date_of_birth"
                          id="date_of_birth"
                          value={data?.date_of_birth}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {/* HOMETOWN */}
                      <div className="member-form-column">
                        <label htmlFor="">Hometown<span class="text-danger">*</span></label>
                        <input
                          type="text"
                          onChange={(e) => handleChange(e)}
                          name="hometown"
                          id="hometown"
                          value={data?.hometown}
                        />
                      </div>
                      {/* NATIONALITY */}
                      <div className="member-form-column">
                        <label htmlFor="">Nationality<span class="text-danger">*</span></label>
                        <input
                          type="text"
                          name="nationality"
                          id="nationality"
                          value={data?.nationality}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    {/* FOURTH ROW */}
                    <div className="member-form-row">
                      {/* RESIDENCE */}
                      <div className="member-form-column">
                        <label htmlFor="">Residence<span class="text-danger">*</span></label>
                        <input
                          type="text"
                          onChange={(e) => handleChange(e)}
                          name="residence"
                          id="residence"
                          value={data?.residence}
                        />
                      </div>
                      {/* LANGUAGE SPOKEN */}
                      <div className="member-form-column">
                        <label htmlFor="">Language Spoken<span class="text-danger">*</span></label>
                        <input
                          type="text"
                          name="language_spoken"
                          id="language_spoken"
                          onChange={(e) => handleChange(e)}
                          value={data?.language_spoken}
                        />
                      </div>
                      {/* EMPTY SPACE */}
                      <div
                        style={{ visibility: "hidden" }}
                        className="member-form-column"
                      >
                        <label htmlFor=""></label>
                        <input type="text" />
                      </div>
                    </div>
                    {/* FIFTH ROW */}
                    <div className="member-form-row">
                      {/* MARITAL STATUS */}
                      <div className="member-form-column">
                        <label htmlFor="">Marital Status
                        <span class="text-danger">*</span></label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="marital_status"
                              value="Single"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.marital_status === "Single"}
                            />{" "}
                            Single
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="marital_status"
                              value="Married"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.marital_status === "Married"}
                            />{" "}
                            Married
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="marital_status"
                              value="Divorced"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.marital_status === "Divorced" }
                            />{" "}
                            Divorced
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="marital_status"
                              value="Remarried"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.marital_status === "Remarried"}
                            />{" "}
                            Remarried
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="marital_status"
                              value="Co-Habitation"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.marital_status==="Co-Habitation"}
                            />{" "}
                            Co - Habitation
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="personal-info-image">
                    <div className="image-container">
                      <img
                        src={file ? URL.createObjectURL(file) : UserImage}
                        alt="user"
                      />
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      name="profile_picture"
                      id="profile_picture"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                    <label id="upload-button" htmlFor="profile_picture">
                      <BsFillCloudUploadFill />
                      Upload File
                    </label>
                    <p style={{ fontWeight: "400", fontSize: "12px" }}>
                      {file ? file.name : "No file selected"}
                    </p>
                  </div>
                </div>
              </div>

              {/* SPIRITUAL LIFE INFORMATION */}
              <div className="member-form-section">
                <div className="member-form-section-header">
                  SPIRITUAL LIFE INFORMATION
                </div>
                <div className="member-form-body">
                  <div className="member-form-details">
                    {/* FIRST ROW */}
                    <div className="member-form-row">
                      {/* PREVIOUS CHURCH */}
                      <div className="member-form-column">
                        <label htmlFor="">Previous Church</label>
                        <input
                          type="text"
                          name="previous_church"
                          id="previous_church"
                          onChange={(e) => handleChange(e)}
                          value = {data?.previous_church}
                        />
                      </div>
                      {/* LOCATION */}
                      <div className="member-form-column">
                        <label htmlFor="">Location</label>
                        <input
                          type="text"
                          onChange={(e) => handleChange(e)}
                          name="location"
                          id="location"
                          value={data?.location}
                        />
                      </div>
                      {/* PASTOR IN CHARGE */}
                      <div className="member-form-column">
                        <label htmlFor="">Pastor In Charge</label>
                        <input
                          type="text"
                          name="pastor_in_charge"
                          id="pastor_in_charge"
                          onChange={(e) => handleChange(e)}
                          value={data?.pastor_in_charge}
                        />
                      </div>
                    </div>
                    {/* SECOND ROW */}
                    <div className="member-form-row">
                      {/* REASON FOR LEAVING */}
                      <div className="member-form-column">
                        <label htmlFor="">Reason For Leaving</label>
                        <input
                          type="text"
                          name="leaving_reason"
                          id="leaving_reason"
                          onChange={(e) => handleChange(e)}
                          value={data?.leaving_reason}
                        />
                      </div>
                    </div>
                    {/* THIRD ROW */}
                    <div className="member-form-row">
                      {/* DATE */}
                      <div className="member-form-column">
                        <label htmlFor="">Contact Number</label>
                        <input
                          type="text"
                          name="contact_number"
                          id="contact_number"
                          value={data?.contact_number}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {/* TITHE CARD NUMBER */}
                      <div className="member-form-column">
                        <label htmlFor="">Tithe Card Number</label>
                        <input
                          type="text"
                          name="tithe_number"
                          id="tithe_number"
                          value={data?.tithe_number}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {/* AREA OF MINISTRY */}
                      <div className="member-form-column">
                        <label htmlFor="">Area of Ministry/Gifting</label>
                        <input
                          type="text"
                          onChange={(e) => handleChange(e)}
                          name="ministry"
                          id="ministry"
                          value={data?.ministry}
                        />
                      </div>
                    </div>
                    {/* FOURTH ROW */}
                    <div className="member-form-row">
                      {/* DATE OF MEMBERSHIP CLASS COMPLETION */}
                      <div className="member-form-column">
                        <label htmlFor="">
                          Date You Completed Membership Class
                        </label>
                        <input
                          type="date"
                          name="membership_class_completion_date"
                          id="membership_class_completion_date"
                          onChange={(e) => handleChange(e)}
                          value={data?.membership_class_completion_date}
                        />
                      </div>
                      {/* BAPTISM DATE */}
                      <div className="member-form-column">
                        <label htmlFor="">Baptismal Date<span class="text-danger">*</span></label>
                        <input
                          type="date"
                          name="baptismal_date"
                          id="baptismal_date"
                          onChange={(e) => handleChange(e)}
                          value={data?.baptismal_date}
                        />
                      </div>
                      {/* BAPTISMAL TYPE */}
                      <div className="member-form-column">
                        <label htmlFor="">Baptismal Type<span class="text-danger">*</span></label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="baptismal_type"
                              value="Immersion"
                              defaultChecked={data?.baptismal_type === "Immersion"}
                              onChange={(e) => handleChange(e)}
                            />{" "}
                            Immersion
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="baptismal_type"
                              value="Sprinkle"
                              defaultChecked={data?.baptismal_type === "Sprinkle"}
                              onChange={(e) => handleChange(e)}
                            />{" "}
                            Sprinkle
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="baptismal_type"
                              value="Other"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.baptismal_type === "Other"}
                            />{" "}
                            Other
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* FIFTH ROW */}
                    <div className="member-form-row">
                      {/* ARE YOU BORN AGAIN */}
                      <div className="member-form-column">
                        <label htmlFor="">Are You Born Again?<span class="text-danger">*</span></label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              onChange={(e) => handleChange(e)}
                              type="radio"
                              name="born_again"
                              value="Yes"
                              defaultChecked={data?.born_again === "Yes"}
                            />{" "}
                            Yes
                          </div>
                          <div className="radio-item">
                            <input
                              onChange={(e) => handleChange(e)}
                              type="radio"
                              name="born_again"
                              value="No"
                              defaultChecked={data?.born_again === "No"}
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>
                      {/* HOLY SPIRIT BAPTIZED */}
                      <div className="member-form-column">
                        <label htmlFor="">Holy Spirit Baptized?<span class="text-danger">*</span></label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="holy_spirit_baptized"
                              value="Yes"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.holy_spirit_baptized === "Yes"}
                            />{" "}
                            Yes
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="holy_spirit_baptized"
                              value="No"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.holy_spirit_baptized === "No"}
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>
                      {/* SPEAKING IN TONGUES */}
                      <div className="member-form-column">
                        <label htmlFor="">Speaking in Tongues<span class="text-danger">*</span></label>
                        <div className="radio-row">
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="speaking_in_tongues"
                              value="Yes"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.speaking_in_tongues === "Yes"}
                            />{" "}
                            Yes
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              name="speaking_in_tongues"
                              value="No"
                              onChange={(e) => handleChange(e)}
                              defaultChecked={data?.speaking_in_tongues === "No"}
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* FAMILY INFORMATION */}
              <div className="member-form-section">
                <div className="member-form-section-header">
                  FAMILY INFORMATION
                </div>
                <div className="member-form-body">
                  <div className="member-form-details">
                    {/* FIRST ROW */}
                    <div className="member-form-row">
                      {/* NAME OF SPOUSE */}
                      <div className="member-form-column">
                        <label htmlFor="">Name of Spouse</label>
                        <input
                          type="text"
                          name="spouse_name"
                          id="spouse_name"
                          onChange={(e) => handleChange(e)}
                          value={data?.spouse_name}
                        />
                      </div>
                      {/* DATE OF MARRIAGE */}
                      <div className="member-form-column">
                        <label htmlFor="">Date of Marriage</label>
                        <input
                          type="date"
                          name="date_of_marriage"
                          id="date_of_marriage"
                          onChange={(e) => handleChange(e)}
                          value={data?.date_of_marriage}
                        />
                      </div>
                    </div>
                    {/* SECOND ROW */}
                    <div className="member-form-row">
                      {/* TYPE OF MARRIAGE */}
                      <div className="member-form-column">
                        <label htmlFor="">Type of Marriage</label>
                        <input
                          type="text"
                          name="type_of_marriage"
                          id="type_of_marriage"
                          onChange={(e) => handleChange(e)}
                          value={data?.type_of_marriage}
                        />
                      </div>
                      {/* SPOUSE CHURCH */}
                      <div className="member-form-column">
                        <label htmlFor="">Spouse Church</label>
                        <input
                          type="text"
                          name="spouse_church"
                          id="spouse_church"
                          onChange={(e) => handleChange(e)}
                          value={data?.spouse_church}
                        />
                      </div>
                    </div>
                    {/* THIRD ROW */}
                    <div className="member-form-row">
                      {/* OCCUPATION OF SPOUSE */}
                      <div className="member-form-column">
                        <label htmlFor="">Occupation of spouse</label>
                        <input
                          type="text"
                          name="occupation_of_spouse"
                          id="occupation_of_spouse"
                          onChange={(e) => handleChange(e)}
                          value={data?.occupation_of_spouse}
                        />
                      </div>
                      {/* SPOUSE CONTACT NUMBER*/}
                      <div className="member-form-column">
                        <label htmlFor="">Spouse Contact Number</label>
                        <input
                          type="text"
                          name="spouse_contact_number"
                          id="spouse_contact_number"
                          onChange={(e) => handleChange(e)}
                          value={data?.spouse_contact_number}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* CHILDREN / DEPENDANT */}
              <div className="member-form-section">
                <div className="member-form-section-header">
                  CHILDREN / DEPENDENTS
                </div>
                <div className="member-form-body">
                  <div className="member-form-details">
                    {childFormFields.map((childFormField, index) => (
                      <div className="dependant-form-details">
                        {/* FIRST ROW */}
                        <div className="member-form-row">
                          {/* SRL */}
                          <div className="member-form-column">
                            <label htmlFor="">SRL</label>
                            <input
                              type="text"
                              name="srl"
                              id="srl"
                              onChange={(e) =>
                                handleChildFormFieldsChange(e, index)
                              }
                              value={childFormFields[index]['srl']}
                            />
                          </div>
                          {/* NAME */}
                          <div className="member-form-column">
                            <label htmlFor="">Name</label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              onChange={(e) =>
                                handleChildFormFieldsChange(e, index)
                              }
                              value={childFormFields[index]['name']}
                            />
                          </div>
                          {/* DATE OF BIRTH */}
                          <div className="member-form-column">
                            <label htmlFor="">Date of birth</label>
                            <input
                              type="date"
                              name="date_of_birth"
                              id="date_of_birth"
                              onChange={(e) =>
                                handleChildFormFieldsChange(e, index)
                              }
                              value={childFormFields[index]['date_of_birth']}
                            />
                          </div>
                        </div>
                        {/* SECOND ROW */}
                        <div className="member-form-row">
                          {/* CHILD GENDER */}
                          <div className="member-form-column">
                            <label htmlFor="">Gender</label>
                            <div className="radio-row">
                              <div className="radio-item">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Male"
                                  onChange={(e) =>
                                    handleChildFormFieldsChange(e, index)
                                  }
                                  defaultChecked={childFormFields[index]['gender'] === "Male"}
                                />{" "}
                                Male
                              </div>
                              <div className="radio-item">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                  onChange={(e) =>
                                    handleChildFormFieldsChange(e, index)
                                  }
                                  defaultChecked={childFormFields[index]['gender'] === "Female"}
                                />{" "}
                                Female
                              </div>
                            </div>
                          </div>
                          {/* RELATIONSHIP */}
                          <div className="member-form-column">
                            <label htmlFor="">Relationship</label>
                            <input
                              type="text"
                              name="relationship"
                              id="relationship"
                              onChange={(e) =>
                                handleChildFormFieldsChange(e, index)
                              }
                              value={childFormFields[index]['relationship']}
                            />
                          </div>
                          {/* EMPTY SPACE */}
                          <div
                            style={{ visibility: "hidden" }}
                            className="member-form-column"
                          >
                            <label htmlFor=""></label>
                            <input type="text" />
                          </div>
                        </div>
                        {/* REMOVE CHILD BUTTON */}
                        {index > 0 && (
                          <div
                            onClick={() => removeChildFields(index)}
                            className="remove-child-button"
                          >
                            + Remove This Child
                          </div>
                        )}
                      </div>
                    ))}

                    {/* ADD MORE BUTTON */}
                    <div
                      className="add-more-child-button"
                      onClick={addChildFields}
                    >
                      + Add Another Child
                    </div>
                  </div>
                </div>
              </div>

              <div className="continue-button">
                <div
                  className="default-button"
                  onClick={() => {
                    // setData({ ...data, children: childFormFields });
                    setPage(2);
                  }}
                >
                  Continue
                </div>
              </div>
            </div>
          )}

          {/* PAGE 2 */}
          {page === 2 && (
            <MemberForm2 setPage={setPage} submitForm={submitForm} handleChange={handleChange} data={data} submitting={submitting}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddMember;
