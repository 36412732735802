import React from "react";
import "./ContactUs.scss";
import Navbar from "../../WebsiteComponents/Navbar/Navbar";
import Footer from "../../WebsiteComponents/Footer/Footer";

const ContactUs = () => {
  return (
    <div>
      <Navbar />
      <div id="contact-us-page">
        <div className="contact-row">
          <h3 className="row-title">
            LOCATION
          </h3>
          <div className="location">
            
            <p>Opposite Christian Service University College,</p>
            <p>Odeneho Kwadaso Road</p>
            <p>Digital Address: AK-020-5834</p>
          </div>
        </div>
        <div className="contact-row">
          <h3 className="row-title">
            CONTACT
          </h3>
          <div className="contact">
            
            <p>TELEPHONE:(+233) 571 124 180 / (+233) 570 947 621 </p>
            <p>HELPLINE: (+233) 543 308 187</p>
            <p>EMAIL: info@royalec.org</p>
          </div>
        </div>
        <div className="contact-row">
        <h3 className="row-title">
            SEND US A MESSAGE
          </h3>
          <form action="" className="contact-form">
            <div className="form-input">
              <label htmlFor="">Your Name</label>
              <input type="text" placeholder="Enter your name" name="name" id="name" />
            </div>
            <div className="form-input">
              <label htmlFor="">Your Email</label>
              <input type="email" name="email" placeholder="Enter your email" id="email" />
            </div>
            <div className="form-input">
              <label htmlFor="">Mobile Number</label>
              <input type="text" name="mobile_number" id="mobile_number" placeholder="Enter your mobile number" />
            </div>
            <div className="form-input">
              <label htmlFor="">Message</label>
              <textarea name="message" id="message" placeholder="Enter Message" cols="30" rows="10"></textarea>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
